import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { OrderDTO, OrderStatus } from '../../../../api/api';
import Table from '../../../../blocks/table/TableClient';
import { HorizontalTabProps } from '../../../../components/tabs-horizontal/TabsHorizontal';
import GoodsService from '../../../../services/GoodsService';
import NotificationService from '../../../../services/NotificationService';
import { log } from '../../../../utils/logging/log';
import { useDispatch } from 'react-redux';
import { setSelectedOrderReceipt } from '../../../../stateManagement/reducers/orderGoodsReducer';
import useOrderTable from './useOrderTable';

interface Props extends HorizontalTabProps {
  handleOpenOrderReceipt: () => void;
}

const OrdersTab = (props: Props) => {
  const { handleOpenOrderReceipt } = props;
  const [loadingOrders, setLoadingOrders] = useState(false);
  const [orders, setOrders] = useState<OrderDTO[]>([]);
  const dispatch = useDispatch();

  const fetchTransferOrders = useCallback(() => {
    setLoadingOrders(true);
    GoodsService.getActiveOrdersByUser()
      .then((res) => {
        setOrders(res);
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke hente dine bestillinger');
      })
      .finally(() => setLoadingOrders(false));
  }, []);

  useEffect(() => {
    fetchTransferOrders();
  }, [fetchTransferOrders]);

  const openOrderReceipt = useCallback(
    (order?: OrderDTO) => {
      if (!order) return;
      dispatch(setSelectedOrderReceipt(order));
      handleOpenOrderReceipt();
    },
    [dispatch, handleOpenOrderReceipt]
  );

  const moveToCar = useCallback((id: number) => {
    GoodsService.moveToVehicle(id)
      .then(() => {
        NotificationService.success('Bestilling flyttet til bil');
        setOrders((prevOrders) =>
          prevOrders.map((order) => (order.orderId === id ? { ...order, orderStatus: OrderStatus.Received } : order))
        );
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Kunne ikke flytte bestilling til bil');
      });
  }, []);

  const tableInstance = useOrderTable(orders, moveToCar, openOrderReceipt);

  return (
    <Container>
      <Table loading={loadingOrders} tableInstance={tableInstance} alwaysShowSort noPadding showPagination></Table>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;

  .table-footer {
    justify-content: end;
  }
`;

export default OrdersTab;
