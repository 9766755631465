import { InputAdornment } from '@mui/material';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import { AddIcon } from '../../../../assets/icons/AddIcon';
import { ItemDTO, StockDTO, WarehouseDTO } from '../../../../api/api';
import { DeleteIcon } from '../../../../assets/icons/DeleteIcon';
import { SearchIcon } from '../../../../assets/icons/SearchIcon';
import AutoComplete from '../../../../components/auto-complete/AutoComplete';
import Button from '../../../../components/button/Button';
import IconButton from '../../../../components/icon-button/IconButton';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../../../components/table/Table';
import TextField from '../../../../components/text-field/TextField';
import GoodsService from '../../../../services/GoodsService';
import NoDataTableRow from '../components/NoDataTableRow';
import NotificationService from '../../../../services/NotificationService';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import { log } from '../../../../utils/logging/log';
import { useSelector } from 'react-redux';
import { selectUserProfile } from '../../../../stateManagement/reducers/userProfileReducer';
import { RemoveIcon } from '../../../../assets/icons/RemoveIcon';
import DialogViewPageHeader from '../../../../components/dialog-view-page-header/DialogViewPageHeader';
import { ContentFooterContainer, Footer, FooterLeft, FooterRight } from '../../../../blocks/tabs-vertical/TabsVertical';
import Typography from '../../../../components/typography/Typography';

const numbersOnlyReg = new RegExp('^[0-9]+$');

interface SelectedStock extends StockDTO {
  amount: string;
}

interface Props {
  handleReturn: () => void;
  taskId?: string;
  projectNumber?: string;
  projectLegalEntity?: string;
}

const ConsumeGoodsPage = (props: Props) => {
  const { handleReturn, taskId, projectNumber, projectLegalEntity } = props;

  const userProfile = useSelector(selectUserProfile).userProfile;

  const [loading, setLoading] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [availableStock, setAvailableStock] = useState<StockDTO[]>([]);
  const [selectedStock, setSelectedStock] = useState<SelectedStock[]>([]);
  const [warehouses, setWarehouses] = useState<WarehouseDTO[]>([]);
  const [selectedWarehouse, setSelectedWarehouse] = useState<WarehouseDTO | null>(null);

  useEffect(() => {
    setLoading(true);
    GoodsService.getWarehouses()
      .then((res) => {
        setWarehouses(res.filter((w) => !w.warehouseId?.startsWith('BE-')));
      })
      .catch((error) => {
        log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (selectedWarehouse?.legalEntityId && selectedWarehouse.warehouseId) {
      setLoading(true);
      GoodsService.getItemsOnHand3(selectedWarehouse.legalEntityId, selectedWarehouse.warehouseId)
        .then((res) => {
          setAvailableStock(res);
        })
        .catch((error) => {
          log(error);
        })
        .finally(() => setLoading(false));
    }
  }, [selectedWarehouse]);

  const handleSelectWarehouse = useCallback(
    (warehouseId: string, reason?) => {
      if (reason && reason === 'clear') {
        setSelectedWarehouse(null);
        setAvailableStock([]);
      }

      const warehouse = warehouses.find((warehouse) => warehouse.warehouseId === warehouseId);
      warehouse && setSelectedWarehouse(warehouse);
      setSelectedStock([]);
    },
    [warehouses]
  );

  useEffect(() => {
    handleSelectWarehouse(userProfile.vehicle?.vehicleId ?? userProfile.defaultWarehouseId ?? '');
  }, [handleSelectWarehouse, userProfile]);

  const filteredStock = useMemo(() => {
    if (searchTerm && availableStock.length > 0) {
      return availableStock.filter(
        (stock) =>
          stock.product?.name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          stock.product?.itemId?.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }
    return availableStock;
  }, [availableStock, searchTerm]);

  const handleAddStock = (stockToAdd: StockDTO) => {
    if (
      selectedStock.some(
        (stock) =>
          stock?.product?.itemId === stockToAdd?.product?.itemId &&
          stock?.product?.batchNumber === stockToAdd?.product?.batchNumber &&
          stock?.product?.serialNumber === stockToAdd?.product?.serialNumber
      )
    ) {
      return;
    } else {
      setSelectedStock((prevStocks) => [...prevStocks, { ...stockToAdd, amount: '1' }]);
    }
  };

  const handleDeleteStock = (stockToDelete: StockDTO) => {
    const tmpSelectedStocks = [...selectedStock];
    setSelectedStock(
      tmpSelectedStocks.filter(
        (stock) =>
          !(
            stock?.product?.itemId === stockToDelete?.product?.itemId &&
            stock?.product?.batchNumber === stockToDelete?.product?.batchNumber &&
            stock?.product?.serialNumber === stockToDelete?.product?.serialNumber
          )
      )
    );
  };

  const handleDecrementStock = (item: SelectedStock) => {
    setSelectedStock((prevStocks) => {
      return prevStocks.map((stock) => {
        return stock.product?.itemId === item.product?.itemId &&
          stock?.product?.batchNumber === item?.product?.batchNumber &&
          stock?.product?.serialNumber === item?.product?.serialNumber
          ? { ...stock, amount: parseFloat(stock.amount) - 1 + '' }
          : stock;
      });
    });
  };

  const handleIncrementStock = (item: SelectedStock) => {
    setSelectedStock((prevStocks) => {
      return prevStocks.map((stock) => {
        return stock.product?.itemId === item?.product?.itemId &&
          stock?.product?.batchNumber === item?.product?.batchNumber &&
          stock?.product?.serialNumber === item?.product?.serialNumber
          ? {
              ...stock,
              amount: stock.amount ? parseFloat(stock.amount) + 1 + '' : '1'
            }
          : stock;
      });
    });
  };

  const handleChangeStock = (item: SelectedStock, newValue?: string, available?: number) => {
    if (newValue === undefined || available === undefined) return;
    if ((newValue && !numbersOnlyReg.test(newValue)) || parseFloat(newValue) > available) return;

    setSelectedStock((prevStocks) => {
      return prevStocks.map((stock) => {
        return stock.product?.itemId === item?.product?.itemId &&
          stock?.product?.batchNumber === item?.product?.batchNumber &&
          stock?.product?.serialNumber === item?.product?.serialNumber
          ? { ...stock, amount: newValue ? parseFloat(newValue) + '' : '' }
          : stock;
      });
    });
  };

  const handleConsumeItems = () => {
    if (taskId) {
      setLoading(true);
      const items: ItemDTO[] = [...selectedStock].map((stock) => {
        return {
          itemId: stock.product?.itemId,
          itemName: stock.product?.name,
          qty: parseFloat(stock.amount),
          batchNumber: stock.product?.batchNumber,
          serialNumber: stock.product?.serialNumber
        };
      });
      GoodsService.consumeItems({
        items,
        workTaskId: parseInt(taskId),
        legalEntityId: selectedWarehouse?.legalEntityId,
        warehouseId: selectedWarehouse?.warehouseId,
        projectId: projectNumber,
        projectLegalEntityId: projectLegalEntity
      })
        .then(() => {
          NotificationService.success(items.length + ' varer forbrugt');
          handleReturn();
          setSelectedStock([]);
        })
        .catch((error) => {
          log(error);
          NotificationService.error('Der opstod en fejl ved forbrug af varer');
        })
        .finally(() => setLoading(false));
    }
  };

  const renderAmountPicker = (stock: SelectedStock) => {
    const itemId = stock?.product?.itemId;
    if (itemId) {
      return (
        <ButtonContainer>
          <IconButton
            disabled={parseFloat(stock.amount) <= 1 || stock.amount === ''}
            onClick={() => handleDecrementStock(stock)}
            padding="8px"
            variant="outlined"
          >
            <RemoveIcon size="16px" />
          </IconButton>
          <TextField
            value={stock.amount}
            onChange={(e) => handleChangeStock(stock, e.target.value, stock.available)}
            style={{ width: '70px' }}
            type="tel"
          />
          <IconButton
            disabled={!stock.available || stock.available <= parseFloat(stock.amount)}
            onClick={() => handleIncrementStock(stock)}
            padding="8px"
            variant="outlined"
          >
            <AddIcon size="16px" />
          </IconButton>
        </ButtonContainer>
      );
    }
  };

  return (
    <>
      <Container>
        {loading && <LoadingOverlay />}
        <div>
          <DialogViewPageHeader handleReturn={handleReturn} title="Fremsøg varer du vil forbruge" />

          <FiltersContainer>
            <TextField
              label="Søg på vare"
              fullWidth
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon size="18px" />
                  </InputAdornment>
                )
              }}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
            <AutoComplete
              fullWidth
              onChange={(_, value, reason) => handleSelectWarehouse(value?.warehouseId ?? '', reason)}
              getOptionLabel={(warehouse) => `(${warehouse.warehouseId}) ${warehouse.name}`}
              options={warehouses}
              value={selectedWarehouse}
              isOptionEqualToValue={(option, value) => option.warehouseId === value.warehouseId}
              renderInput={(params) => <TextField {...params} label={'Vælg lager'} fullWidth />}
              noOptionsText={'Intet lager'}
            />
          </FiltersContainer>
        </div>

        <div>
          <StyledTypography variant="h5" fontWeight="bold">
            Lager: {selectedWarehouse?.name}
          </StyledTypography>
          <div>
            <TableContainer maxHeight="400px">
              <Table stickyHeader sx={{ height: 'max-content' }} className="TEEEEEST">
                <TableHead>
                  <TableRow style={{ height: '50px' }}>
                    <TableCell sx={{ width: '40%' }}>Varenummer</TableCell>
                    <TableCell sx={{ width: '20%' }}>Varenavn</TableCell>
                    <TableCell sx={{ width: '15%' }}>Min/Max</TableCell>
                    <TableCell sx={{ width: '15%' }}>Antal på lager</TableCell>
                    <TableCell sx={{ width: '10%' }}></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredStock && filteredStock.length > 0 ? (
                    filteredStock.map((stock, i) => {
                      const idSuffix = stock.product?.batchNumber || stock.product?.serialNumber;
                      return (
                        <TableRow key={i}>
                          <TableCell>{stock.product?.itemId + (idSuffix ? ` (${idSuffix})` : '')}</TableCell>
                          <TableCell>{stock?.product?.name ?? '-'}</TableCell>
                          <TableCell>{stock.minOnHand + '/' + stock.maxOnHand}</TableCell>
                          <TableCell>{stock?.available !== undefined ? stock.available : '-'}</TableCell>
                          <TableCell align="right">
                            <StyledIconButton
                              disabled={!stock.available}
                              onClick={() => handleAddStock(stock)}
                              padding={'10px'}
                              variant="primary"
                            >
                              <AddIcon size="12px" />
                            </StyledIconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <NoDataTableRow
                      text={selectedWarehouse ? 'Fandt ingen varer på det valgte lager' : 'Vælg lager for at se varer'}
                    />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>

        <div>
          <Row>
            <StyledTypography variant="h5" fontWeight="bold">
              Valgte varer
            </StyledTypography>
            <Button variant="secondary" onClick={() => setSelectedStock([])}>
              <DeleteIcon size="20px" />
              Ryd valgte varer
            </Button>
          </Row>
          <TableContainer>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ width: '35%' }}>Varenummer</TableCell>
                  <TableCell sx={{ width: '20%' }}>Varenavn</TableCell>
                  <TableCell sx={{ width: '30%' }} align="center">
                    Antal
                  </TableCell>
                  <TableCell sx={{ width: '15%' }}></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {selectedStock && selectedStock.length > 0 ? (
                  selectedStock.map((stock, i) => {
                    const idSuffix = stock.product?.batchNumber || stock.product?.serialNumber;
                    return (
                      <TableRow key={i}>
                        <TableCell>{stock.product?.itemId + (idSuffix ? ` (${idSuffix})` : '')}</TableCell>
                        <TableCell>{stock?.product?.name ?? '-'}</TableCell>
                        <TableCell align="center">{renderAmountPicker(stock)}</TableCell>
                        <TableCell align="right">
                          <StyledIconButton onClick={() => handleDeleteStock(stock)} padding={'10px'}>
                            <DeleteIcon size="20px" />
                          </StyledIconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })
                ) : (
                  <NoDataTableRow text="Ingen valgte varer" />
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </Container>
      <ContentFooterContainer>
        <Footer>
          <FooterLeft></FooterLeft>
          <FooterRight>
            <Button isLoading={loading} variant="primary" onClick={() => handleConsumeItems()}>
              Forbrug varer
            </Button>
          </FooterRight>
        </Footer>
      </ContentFooterContainer>
    </>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  position: relative;
`;

const FiltersContainer = styled.div`
  display: flex;
  column-gap: 24px;
  width: 85%;
`;

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
`;

const Row = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(props) => props.theme.spacing(6)};
`;

const StyledIconButton = styled((props) => <IconButton {...props} />)`
  margin-right: 0.75rem;
`;

export default ConsumeGoodsPage;
