import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@mui/material';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';

import TimeRegTableUser from '../../blocks/time-registration-list/TimeRegTableUser';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import Typography from '../../components/typography/Typography';
import { StyledCircularProgress } from '../../styling/Styling';
import n1LightTheme from '../../theme/light-theme';
import { useNavigate } from '../../hooks/useNavigate';
import { AddIcon } from '../../assets/icons/AddIcon';
import Button from '../../components/button/Button';
import WeekDatePicker from '../../components/week-date-picker/WeekDatePicker';
import Alert from '../../components/alert/Alert';
import TimeRegDayHeader from './time-reg-day-header/TimeRegDayHeader';
import { AccordionSummaryStyle, TimeRegViewLayoutProps } from './TimeRegViewShell';
import {
  addTimeRegistration,
  editTimeRegistration,
  TimeRegistrationSO
} from '../../stateManagement/reducers/timeRegistrationReducer';
import { ADD_TIME_ROUTE } from '../../utils/constants';
import addQueryParams from '../../utils/routing/add-query-params';

const TimeRegViewDefault = (props: TimeRegViewLayoutProps) => {
  const {
    sumOfApprovedHours,
    registrationsForDay,
    isLoading,
    expandedDays,
    toggleDayExpansion,
    handleDeleteRegistration,
    currentWeekDays,
    setCurrentWeekDays,
    errors,
    approving,
    handleApproveDay
  } = props;
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const handleOpenRegistration = (timeReg?: TimeRegistrationSO) => {
    if (timeReg) {
      dispatch(editTimeRegistration(timeReg));
    } else {
      dispatch(addTimeRegistration({}));
    }
    navigate(addQueryParams(undefined, undefined, ADD_TIME_ROUTE));
  };

  return (
    <StyledContainer>
      <StyledHeader>
        <Typography variant="h2">Tidsoversigt</Typography>
        <StyledIconButton onClick={() => handleOpenRegistration()} data-testid="add-timereg-user-button">
          <AddIcon size="20px" />
        </StyledIconButton>
        <WeekDatePicker currentWeekDays={currentWeekDays} onDateChange={setCurrentWeekDays} />
        <Typography variant="h4">
          <b>{sumOfApprovedHours}</b> timer
        </Typography>
      </StyledHeader>
      {isLoading ? (
        <CircularProgress />
      ) : (
        registrationsForDay.map((r) => {
          return (
            <Accordion
              disableGutters
              sx={{ '&:before': { display: 'none' } }}
              expanded={expandedDays.includes(r.day)}
              key={r.day}
            >
              <AccordionSummary sx={AccordionSummaryStyle} onClick={() => toggleDayExpansion(r.day)}>
                <TimeRegDayHeader registrationsForDay={r} />
              </AccordionSummary>
              <AccordionDetails sx={{ border: `1px solid ${n1LightTheme.palette.grey.black10}` }}>
                <StyledAccordionDetailsDiv>
                  <TimeRegTableUser
                    handleEdit={(timeReg: TimeRegistrationSO) => handleOpenRegistration(timeReg)}
                    handleDelete={(id) => handleDeleteRegistration(id)}
                    timeRegistrations={r.registrations}
                    errors={errors}
                  />
                  {r.registrations.length > 0 && (
                    <StyledFooterDiv>
                      {errors.length > 0 && (
                        <Alert margin="0px 10px" severity="warning" height="30px">
                          En eller flere timeregistreringer fejlede. Prøv igen eller lav registreringen forfra.
                        </Alert>
                      )}
                      <Button
                        disabled={!r.registrations.some((reg) => !reg.approvedByUser)}
                        onClick={() => handleApproveDay(r.registrations)}
                        data-testid="confirm-hours"
                      >
                        {approving ? <StyledCircularProgress size={16} /> : <div>Godkend</div>}
                      </Button>
                    </StyledFooterDiv>
                  )}
                </StyledAccordionDetailsDiv>
              </AccordionDetails>
            </Accordion>
          );
        })
      )}
    </StyledContainer>
  );
};

const StyledFooterDiv = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: flex-end;
  margin-top: 16px;
`;

const StyledContainer = styled.div`
  overflow: auto;
`;

const StyledAccordionDetailsDiv = styled.div`
  padding: 0px 16px;
`;

const StyledHeader = styled.div`
  height: 100px;
  padding: 0 ${(props) => props.theme.spacing(6)};
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1px;
  background-color: ${(props) => props.theme.palette.background.tertiary};
  column-gap: 0.75rem;
`;

const StyledIconButton = styled((props) => <IconButton {...props} />)`
  && {
    margin-left: auto;
  }
`;

export default TimeRegViewDefault;
