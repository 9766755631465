import WorkClient from '../api/workClient';

const getDropPointsByDistance = (latitude: number, longitude: number) => {
  return WorkClient.getDropPointsByDistance(latitude, longitude);
};

const WarehouseService = {
  getDropPointsByDistance
};

export default WarehouseService;
