import { useEffect, useState } from 'react';
import styled from 'styled-components';
import Button from '../../../components/button/Button';
import { ChevronLeft } from '../../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../../assets/icons/ChevronRight';
import Typography from '../../../components/typography/Typography';
import { GoodsData } from '../../../models/Goods';
import { OrderGoodsPage } from './OrderGoodsContent';
import { ImageErrorIcon } from '../../../assets/icons/ImageErrorIcon';
import n1LightTheme from '../../../theme/light-theme';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

interface Props {
  images?: string[];
  selectedGood?: GoodsData;
  setPage: (page: OrderGoodsPage) => void;
  handleSelectGoods: (goodsId?: string, amount?: number) => void;
}

const ImageViewer = (props: Props) => {
  const { images, selectedGood, handleSelectGoods, setPage } = props;
  const [currentImage, setCurrentImage] = useState(1);
  const [showErrorImage, setShowErrorImage] = useState(false);

  const updateCurrentImage = (action: 'increment' | 'decrement') => {
    if (action === 'increment') {
      if (canIncrement()) setCurrentImage((prev) => prev + 1);
    } else if (action === 'decrement') {
      if (canDecrement()) setCurrentImage((prev) => prev - 1);
    }
  };

  const canIncrement = () => {
    return currentImage !== images?.length;
  };

  const canDecrement = () => {
    return currentImage !== 1;
  };

  useEffect(() => {
    setShowErrorImage(false);
  }, [currentImage]);

  return (
    <Container>
      {images && (
        <ImageContainer>
          {showErrorImage ? (
            <ErrorImage>
              <ImageErrorIcon color={n1LightTheme.palette.grey.black60} size="100px" />
              <Typography margin="30px">Billede ikke tilgængeligt</Typography>
            </ErrorImage>
          ) : (
            <TransformWrapper centerOnInit maxScale={50}>
              <TransformComponent
                wrapperStyle={{ width: '100%', height: '100%' }}
                contentStyle={{ width: '100%', height: '100%' }}
              >
                <Image
                  onError={(e) => {
                    setShowErrorImage(true);
                  }}
                  src={images[currentImage - 1]}
                  alt="Billede ikke tilgængeligt"
                />
              </TransformComponent>
            </TransformWrapper>
          )}
        </ImageContainer>
      )}
      <ButtonBar>
        <StyledButton disabled={!canDecrement()} onClick={() => updateCurrentImage('decrement')} variant="secondary">
          <ChevronLeft size="12px" />
          Forrige
        </StyledButton>
        <Typography variant="p">{`${currentImage} / ${images?.length ?? 0}`}</Typography>
        <StyledButton disabled={!canIncrement()} onClick={() => updateCurrentImage('increment')} variant="secondary">
          Næste
          <ChevronRight size="12px" />
        </StyledButton>
      </ButtonBar>
      <DetailsContainer>
        <Detail>
          <Typography color="darkGrey" variant="h5">
            Varenummer
          </Typography>
          <Typography variant="h5">{selectedGood?.goodsId}</Typography>
        </Detail>
        <Detail>
          <Typography color="darkGrey" variant="h5">
            Søgt navn
          </Typography>
          <Typography variant="h5">{selectedGood?.searchName ?? '-'}</Typography>
        </Detail>
        <Detail>
          <Typography color="darkGrey" variant="h5">
            Varegruppe
          </Typography>
          <Typography variant="h5">{selectedGood?.goodsGroupName}</Typography>
        </Detail>
        <Button
          onClick={() => {
            handleSelectGoods(selectedGood?.goodsId, 1);
            setPage(OrderGoodsPage.SELECT);
          }}
        >
          Læg i kurv
        </Button>
      </DetailsContainer>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 50px;
`;

const ButtonBar = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: ${(props) => props.theme.palette.grey.black5};
  width: 100%;
  align-items: center;
`;

const Image = styled.img`
  object-fit: cover;
  height: 100%;
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 500px;
  width: 100%;
`;

const StyledButton = styled(Button)`
  background-color: transparent;
  border: 0;
  font-size: 14px;
`;

const Detail = styled.div`
  display: flex;
  flex-direction: column;
`;

const DetailsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  width: 100%;
`;

const ErrorImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #dbdbdb;
  width: 100%;
  height: 100%;
  color: ${(props) => props.theme.palette.grey.black60};
`;

export default ImageViewer;
