import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';

import { CreateTransferOrderDTO, LocationDTO } from '../../../api/api';
import Button from '../../../components/button/Button';
import LoadingOverlay from '../../../components/loading-overlay/LoadingOverlay';
import TextField from '../../../components/text-field/TextField';
import GoodsService from '../../../services/GoodsService';
import NotificationService from '../../../services/NotificationService';
import { selectUserProfile } from '../../../stateManagement/reducers/userProfileReducer';
import { log } from '../../../utils/logging/log';
import { Container, DialogContent, StyledTable } from '../components/Styles';
import SelectedGoodsList from '../components/SelectedGoodsList';
import { DeliveryLocation, GoodsData, ProductDetails, SelectedGoods } from '../../../models/Goods';
import DatePicker from '../../../components/date-picker/DatePicker';
import { formatDateString } from '../../../utils/dateHandling';
import SelectedGoodsIcon from '../components/SelectedGoodsBadge';
import {
  selectOrderGoodsDeliveryDate,
  selectOrderGoodsList,
  selectOrderGoodsNotes,
  selectOrderGoodsDeliveryLocation,
  setDeliveryDate,
  setNotes,
  setOrderGoodsList,
  setSelectedDeliveryLocation,
  selectWorkTaskOrderInfo,
  resetOrderGoods
} from '../../../stateManagement/reducers/orderGoodsReducer';
import CriticalOrderPopover from '../../../blocks/critical-order-popover/CriticalOrderPopOver';
import BasicPopover from '../../../components/popover/Popover';
import BasketPopover from '../components/BasketPopover';
import { useGoodsList } from './goods-list/useGoodsList';
import ImageViewer from './ImageViewer';

export enum OrderGoodsPage {
  SELECT = 'select',
  ORDER = 'order',
  PRODUCT_DETAILS = 'product-details'
}
interface Props {
  onClose: () => void;
  page: OrderGoodsPage;
  setPage: (page: OrderGoodsPage) => void;
  orderToUserVeicle: boolean;
  taskLocation?: LocationDTO;
}

const OrderGoodsContent = (props: Props) => {
  const { page, setPage, onClose, orderToUserVeicle, taskLocation } = props;

  const userProfile = useSelector(selectUserProfile).userProfile;
  const selectedGoods = useSelector(selectOrderGoodsList);
  const selectedDeliveryLocation = useSelector(selectOrderGoodsDeliveryLocation);
  const selectedWorkTaskOrderInfo = useSelector(selectWorkTaskOrderInfo);
  const deliveryDate = useSelector(selectOrderGoodsDeliveryDate);
  const notes = useSelector(selectOrderGoodsNotes);

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  const [goods, setGoods] = useState<ProductDetails[]>([]);
  const [deliveryOptions, setDeliveryOptions] = useState<DeliveryLocation[] | undefined>(undefined);
  const [deliveryDateError, setDeliveryDateError] = useState(false);
  const [allowedDays, setAllowedDays] = useState<Date[] | null>([]);
  const [selectedGood, setSelectedGood] = useState<GoodsData>();

  useEffect(() => {
    setIsLoading(true);
    GoodsService.getActiveProducts()
      .then((res) => {
        setGoods(res);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente vareliste');
      })
      .finally(() => setIsLoading(false));
  }, []);

  const fetchDeliveryDays = useCallback(
    async (warehouse?: string) => {
      if (!warehouse) return;
      try {
        const days = (await GoodsService.getAllowedDeliveryDates(warehouse)).map((str) => new Date(str));
        setAllowedDays(days);
        dispatch(setDeliveryDate(''));
      } catch (error) {
        log(error);
        NotificationService.error('Kan ikke hente tilladte datoer for det valgte leveringssted.');
      }
    },
    [dispatch]
  );

  useEffect(() => {
    GoodsService.getAllDeliveryDropPoints()
      .then((res) => {
        const options = res.map((dropPoint) => ({
          id: dropPoint?.warehouseId ?? '',
          name: dropPoint?.name ?? ''
        }));
        setDeliveryOptions(options);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kan ikke hente dropsteder.');
      });
  }, []);

  useEffect(() => {
    const isVehicleWarehouse = selectedDeliveryLocation.id.includes('V-');
    if (!isVehicleWarehouse) {
      setAllowedDays([]);
      dispatch(setDeliveryDate(''));
      return;
    }
    fetchDeliveryDays(selectedDeliveryLocation.id);
  }, [dispatch, fetchDeliveryDays, selectedDeliveryLocation]);

  useEffect(() => {
    if (orderToUserVeicle) {
      dispatch(
        setSelectedDeliveryLocation({
          id: userProfile.vehicle?.vehicleId ?? '',
          name: userProfile.vehicle?.vehicleName ?? ''
        })
      );
    }
  }, [dispatch, orderToUserVeicle, userProfile.vehicle]);

  useEffect(() => {
    if (selectedWorkTaskOrderInfo) {
      const fullNote = `${selectedWorkTaskOrderInfo?.workTaskId} - ${selectedWorkTaskOrderInfo?.address?.streetName} ${selectedWorkTaskOrderInfo?.address?.houseNumber}, ${selectedWorkTaskOrderInfo?.address?.postalCode} ${selectedWorkTaskOrderInfo?.address?.city}`;
      dispatch(setNotes(fullNote.substring(0, 40)));
    }
  }, [dispatch, selectedWorkTaskOrderInfo]);

  const selectedCount = useMemo(() => {
    let count = 0;
    selectedGoods.forEach((goods) => {
      count = count + (goods?.qty ?? 1);
    });
    return count;
  }, [selectedGoods]);

  const handleSelectGoods = useCallback(
    (goodsId?: string, amount?: number) => {
      if (!goodsId) return;
      const amountToAdd = amount ?? 1;
      let totalAddedOfGood = 1;

      const { name, itemId } = goods.find((good) => good?.itemId === goodsId) ?? {};
      const goodsToAdd: SelectedGoods = { name, itemId, qty: amountToAdd };

      if (selectedGoods.some((good) => good?.itemId === goodsToAdd?.itemId)) {
        let tmpSelectedGoods = selectedGoods.map((selectedGood) => {
          if (selectedGood.itemId === goodsToAdd?.itemId) {
            totalAddedOfGood = selectedGood?.qty ? selectedGood.qty + amountToAdd : amountToAdd;
            return { ...selectedGood, qty: totalAddedOfGood };
          } else {
            return selectedGood;
          }
        });
        dispatch(setOrderGoodsList(tmpSelectedGoods));
      } else {
        dispatch(setOrderGoodsList([...selectedGoods, { ...goodsToAdd }]));
        totalAddedOfGood = amountToAdd;
      }
      NotificationService.success(`${totalAddedOfGood} x ${name} ligger nu i kurven`);
    },
    [dispatch, goods, selectedGoods]
  );

  const submitOrder = useCallback(() => {
    if (selectedGoods.some((item) => (item.qty && item?.qty < 1) || !item.qty)) {
      NotificationService.error('Det er ikke muligt at bestille mindre end 1 af en vare');
      return;
    }
    if (!deliveryDate) {
      setDeliveryDateError(true);
      NotificationService.error('Ønsket leveringsdato mangler. Udfyld feltet for at fortsætte.');
      return;
    }
    if (!selectedDeliveryLocation.id) {
      NotificationService.error("Lager er ikke angivet. Tryk tilbage og udfyld feletet 'Til lager' for at fortsætte.");
      return;
    }

    setIsLoading(true);
    let body: CreateTransferOrderDTO = {
      warehouseId: selectedDeliveryLocation.id,
      description: notes,
      items: selectedGoods.map((good) => ({ itemId: good.itemId, qty: good.qty })),
      receiptDate: deliveryDate !== '' ? deliveryDate : undefined,
      workTaskId: selectedWorkTaskOrderInfo?.workTaskId ? parseInt(selectedWorkTaskOrderInfo.workTaskId) : undefined,
      projectId: selectedWorkTaskOrderInfo?.projectNumber,
      projectLegalEntityId: selectedWorkTaskOrderInfo?.projectLegalEntityId
    };
    GoodsService.createTransferOrder(body)
      .then(() => {
        NotificationService.success('Ordre bestilt');
        dispatch(setOrderGoodsList([]));
        dispatch(resetOrderGoods());
        onClose();
      })
      .catch((error) => {
        NotificationService.error(`Kunne ikke oprette ordren: ${error}`);
        log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [selectedGoods, deliveryDate, selectedWorkTaskOrderInfo, selectedDeliveryLocation, notes, dispatch, onClose]);

  const handleSetSelectedGoodsAmount = useCallback(
    (value: number, goodsId?: string) => {
      if (!goodsId) return;

      const tmpSelectedGoods = selectedGoods.map((selectedGood) => {
        return selectedGood.itemId === goodsId ? { ...selectedGood, qty: value } : selectedGood;
      });
      dispatch(setOrderGoodsList(tmpSelectedGoods));
    },
    [dispatch, selectedGoods]
  );

  const removeSelectedGoods = useCallback(
    (goodsId?: string) => {
      if (!goodsId) return;
      const tmpSelectedGoods = selectedGoods.filter((selectedGood) => {
        return selectedGood.itemId !== goodsId;
      });
      dispatch(setOrderGoodsList(tmpSelectedGoods));
    },
    [dispatch, selectedGoods]
  );

  const deliveryDateChanged = (date: Date) => {
    dispatch(setDeliveryDate(date.toISOString()));
    setDeliveryDateError(false);
  };

  const handleDeliveryLocation = useCallback(
    (deliveryLocation: DeliveryLocation) => {
      dispatch(setSelectedDeliveryLocation(deliveryLocation));
      fetchDeliveryDays(deliveryLocation.id);
    },
    [dispatch, fetchDeliveryDays]
  );

  const { tableInstance } = useGoodsList(goods, handleSelectGoods, (good: GoodsData) => {
    setSelectedGood(good);
    setPage(OrderGoodsPage.PRODUCT_DETAILS);
  });

  return (
    <>
      {isLoading && <LoadingOverlay />}
      {page === OrderGoodsPage.SELECT && (
        <Container>
          <DialogContent>
            <StyledTable tableInstance={tableInstance} loading={false} alwaysShowSort showPagination />
            <Footer>
              <BasicPopover
                buttonElement={<SelectedGoodsIcon show count={selectedCount} />}
                popoverElement={<BasketPopover removeGoods={removeSelectedGoods} selectedGoods={selectedGoods} />}
                disablePopover={selectedCount === 0}
                activeByClick
              />
              <Button disabled={selectedCount === 0} onClick={() => setPage(OrderGoodsPage.ORDER)}>
                Gå til bestilling
              </Button>
            </Footer>
          </DialogContent>
        </Container>
      )}

      {page === OrderGoodsPage.ORDER && (
        <Container scroll>
          <DialogContent>
            <SelectedGoodsList
              setGoodsAmount={(value, goodsId) => handleSetSelectedGoodsAmount(value, goodsId)}
              removeGoods={removeSelectedGoods}
              selectedGoods={selectedGoods}
              showAvailable={false}
              showDeliverTo
              selectedDeliveryLocation={selectedDeliveryLocation}
              setDeliveryLocation={handleDeliveryLocation}
              deliveryOptions={deliveryOptions}
              clearSelectedGoods={() => dispatch(setOrderGoodsList([]))}
              renderFullHeight
              taskLocation={taskLocation}
            />
            <Row>
              <StyledDatePicker
                required
                error={deliveryDateError}
                centerY
                disabled={selectedDeliveryLocation.id === ''}
                onDateChanged={(date) => deliveryDateChanged(date)}
                label="Ønsket leveringsdato"
                value={formatDateString(deliveryDate)}
                {...(allowedDays ? { allowedDays } : {})}
              />
              <TextField
                onChange={(e) => dispatch(setNotes(e.target.value))}
                label="Evt. bemærkning til ordren vil blive vist på pakkelabel"
                inputProps={{ maxLength: 40 }}
                helperText="Beskeden kan max indeholde 40 tegn"
                fullWidth
                value={notes}
              />
            </Row>
            <Footer>
              <CriticalOrderPopover
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
              />
              <StyledButton disabled={selectedCount === 0} onClick={submitOrder} isLoading={isLoading}>
                Bestil varer {selectedCount > 0 ? `(${selectedCount})` : ''}
              </StyledButton>
            </Footer>
          </DialogContent>
        </Container>
      )}
      {page === OrderGoodsPage.PRODUCT_DETAILS && (
        <Container scroll>
          <DialogContent>
            <ImageViewer
              setPage={() => setPage(OrderGoodsPage.SELECT)}
              handleSelectGoods={handleSelectGoods}
              selectedGood={selectedGood}
              images={selectedGood?.pictureLinks}
            />
          </DialogContent>
        </Container>
      )}
    </>
  );
};

const StyledDatePicker = styled(DatePicker)`
  width: 50%;
  margin-right: ${(props) => props.theme.spacing(4)};
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  margin: ${(props) => props.theme.spacing(5)} 0;
  justify-content: end;
`;

const Row = styled.div`
  margin-top: ${(props) => props.theme.spacing(5)};
  display: flex;
`;

const StyledButton = styled(Button)`
  margin-left: ${(props) => props.theme.spacing(8)};
`;

export default OrderGoodsContent;
