import { useNavigate } from '../../hooks/useNavigate';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { MouseEvent, useCallback, useEffect, useState } from 'react';

import DetailsStep from './details-step/DetailsStep';
import LocationStep from './location-step/LocationStep';
import TaskTypeStep from './task-type-step/TaskTypeStep';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import {
  deleteTask,
  newTask,
  selectTasks,
  setAssetSearchStatus,
  setFiles,
  updateTask
} from '../../stateManagement/reducers/taskCreationReducer';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import TabsVertical from '../../blocks/tabs-vertical/TabsVertical';
import { FileInfo, TaskCreationTask } from '../../models/TaskCreationTask';
import CreateTaskDialogHeader from './CreateTaskViewHeader';
import DialogView from '../../components/dialog-view/DialogView';
import { WarningIcon } from '../../assets/icons/WarningIcon';
import {
  AssetResultDTO,
  CreateWorkTaskDTO2,
  EmailNotificationTypeEnum,
  SubWorkTaskType,
  WorkTaskStatus
} from '../../api/api';
import Button from '../../components/button/Button';
import { validateBaseFields, validateCustomerAppointment } from '../../utils/validation/taskCreationValidation';
import NotificationService from '../../services/NotificationService';
import TaskService from '../../services/TaskService';
import useTaskFiles from '../../hooks/useTaskFiles';
import AttachmentService from '../../services/AttachmentService';
import { setListShouldUpdate } from '../../stateManagement/reducers/taskListReducer';
import { EDIT_TASK_ROUTE } from '../../utils/constants';
import Alert from '../../components/alert/Alert';
import { log } from '../../utils/logging/log';
import localforage from 'localforage';
import { validateDynamicField } from '../../utils/validation/taskComponentAttributeValidation';
import { selectUserProfile } from '../../stateManagement/reducers/userProfileReducer';
import useQuery from '../../hooks/useQuery';
import removeQueryParams from '../../utils/routing/remove-query-params';
import addQueryParams from '../../utils/routing/add-query-params';
import { QueryParams } from '../../models/QueryParams';
import Checkbox from '../../components/checkbox/Checkbox';
import { extractAttributeValuesFromComponents } from '../../utils/componentHandling';
import { getGuid } from '../../utils/guidGenerating';
import AssetService from '../../services/AssetService';
import { TaskComponent } from '../../models/TaskTypeComponent';
import TaskTypeService from '../../services/TaskTypeService';
import TestingService from '../../services/TestingService';
import { linkToTask } from '../task-view/task-details-view2/utils/LinkToWorkTask';

const planningDepartmentId = 17;

const getConfirmationDialog = (tasktypeName: string, assetId: string): DialogBody => {
  return {
    headerText: 'Er du sikker på, at du vil oprette en opgave?',
    bodyText: `Der ligger allerede en åben ${tasktypeName} på ${assetId}`,
    declineButtonText: 'Fortryd',
    confirmButtonText: 'Bekræft oprettelse'
  };
};

const getActivePanel = (task: TaskCreationTask) => {
  if (task?.creationType !== 'relational') return '1';
  if (task?.creationType === 'relational' && task?.taskType === undefined) return '2';
  return '3';
};

const CreateTaskDialog = () => {
  const { id } = useQuery<QueryParams>();
  const task = useSelector(selectTasks).find((task) => task.taskCreationId === id) as TaskCreationTask;
  const taskCreationId = task?.taskCreationId ?? '';
  const { files, fetchFiles } = useTaskFiles(taskCreationId);
  const [fileErrors, setFileErrors] = useState<Map<number, string>>(new Map());

  const [activePanelId, setActivePanelId] = useState<string>(getActivePanel(task));
  const [activeTasks] = useState<TaskCreationTask[]>(useSelector(selectTasks));
  const [isLoading, setIsLoading] = useState(false);
  const [warningMessage, setWarningMessage] = useState('');
  const [shouldCreateRelationalTask, setShouldCreateRelationalTask] = useState(!!task?.taskType?.requiresPreparationTask);
  const [testingTools] = useState(
    window._env_.ENVIRONMENT === 'Local' || window._env_.ENVIRONMENT === 'Dev' || window._env_.ENVIRONMENT === 'Test'
  );
  const [enableEmailNotification, setEnableEmailNotification] = useState(false);

  const [shouldSimulatePlanning, setShouldSimulatePlanning] = useState(false);

  const userDepartment = useSelector(selectUserProfile).userProfile.department;

  const { getConfirmation } = useConfirmationDialog();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleCloseDialog = async () => {
    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      navigate(removeQueryParams(['id'], EDIT_TASK_ROUTE));
      id !== undefined && dispatch(deleteTask(id));
    }
  };

  const renderTabsHeader = () => {
    if (task) {
      const titlePostText =
        task?.creationType === 'copy'
          ? ` - kopi`
          : task?.creationType === 'relational'
          ? ` - relationsopgave til ${task.parentId}`
          : '';

      const taskTypeText = task?.taskType?.name ? ` - ${task?.taskType?.name}` : '';
      const assetIdText = task?.assetId ? ` - ${task?.assetId}` : '';

      return <h2>Nyt arbejdskort {titlePostText + taskTypeText + assetIdText}</h2>;
    }

    return <></>;
  };

  const createRelationalTask = useCallback(
    async (parentId: number) => {
      let asset: AssetResultDTO = {};
      const taskCreationId = getGuid();
      if (task?.assetId && task.assetType) {
        try {
          setIsLoading(true);
          const response = await AssetService.getAssets(task.assetId, task.assetType);
          asset = response[0];
        } catch (error) {
          NotificationService.error(`Kunne ikke kopiere opgave: ${error}`, 5000);
        } finally {
          setIsLoading(false);
        }
      } else {
        asset = { location: task?.taskLocation, assetType: task?.assetType };
      }

      try {
        const taskTypes = await TaskTypeService.getActiveTaskTypes();
        const relationalTaskTaskType = taskTypes.taskTypeDTOs?.find(
          (t) => t.id === task.taskType?.defaultRelationalTaskTypeId
        );

        dispatch(
          newTask({
            taskLocation: task?.taskLocation,
            description: task?.description,
            taskCreationId: taskCreationId,
            assignedToDepartmentId: task.assignedToDepartmentId,
            notesForPlanning: task?.notesForPlanning,
            assetId: task?.assetId,
            assetLocation: asset?.location,
            assetType: asset?.assetType,
            assetPrimaryVoltage: asset?.primaryVoltage,
            parentId: parentId,
            creationType: 'relational',
            componentsFromParent: extractAttributeValuesFromComponents(task?.components as TaskComponent[]),
            components: relationalTaskTaskType?.taskComponents?.map((section) => {
              return {
                ...section,
                attributes: section?.attributes?.map((obj) => {
                  const attribute = task.components
                    ?.flatMap((obj) => obj.attributes)
                    .find((attribute) => attribute?.attributeId === obj.attributeId);
                  return {
                    ...obj,
                    value: attribute?.value
                  };
                })
              };
            }),
            gisRegion: task?.gisRegion,
            stretches: task?.stretches,
            taskTypeId: task.taskType?.defaultRelationalTaskTypeId,
            accessConditions: task.accessConditions,
            projectNumber: task.projectNumber,
            createdByDepartmentId: userDepartment?.departmentId ?? planningDepartmentId,
            projectLegalEntityId: task.projectLegalEntityId,
            componentValues: task.componentValues,
            ignoreDrivingTimeWhenPlanned: task.ignoreDrivingTimeWhenPlanned,
            earliestStartDate: task.earliestStartDate,
            deadline: task.deadline,
            normTimeMin: relationalTaskTaskType?.normTimeMin,
            taskType: relationalTaskTaskType,
            taskTypePickerInfo: taskTypes
          })
        );
        dispatch(
          setAssetSearchStatus({
            taskCreationId: taskCreationId,
            category: asset.assetType,
            locations: [asset]
          })
        );
        navigate(addQueryParams(undefined, { id: taskCreationId }, EDIT_TASK_ROUTE));
      } catch (error) {
        NotificationService.error('Kunne ikke hente opgavetyper');
        log(error);
      }
    },
    [dispatch, navigate, task, userDepartment?.departmentId]
  );

  const endCreation = useCallback(
    (workTaskId: number) => {
      if (!id) return;
      dispatch(deleteTask(taskCreationId));
      dispatch(setListShouldUpdate(true));
      navigate(removeQueryParams(['id'], EDIT_TASK_ROUTE));
      setIsLoading(false);
      if (enableEmailNotification) {
        TaskService.subscribeToEmailNotifications(workTaskId, EmailNotificationTypeEnum.StatusChange, true).catch(
          (error) => {
            log(error);
          }
        );
      }

      NotificationService.showToast({
        severity: 'success',
        text: 'Oprettede opgave med id ',
        linkText: `${workTaskId}`,
        linkTo: linkToTask(workTaskId + ''),
        duration: 10000
      });

      if (shouldCreateRelationalTask) {
        createRelationalTask(workTaskId);
      }

      if (testingTools) {
        shouldSimulatePlanning && TestingService.simulatePlanning(workTaskId);
      }
    },
    [
      createRelationalTask,
      dispatch,
      enableEmailNotification,
      id,
      navigate,
      shouldCreateRelationalTask,
      shouldSimulatePlanning,
      taskCreationId,
      testingTools
    ]
  );

  const handleFileChanges = useCallback(
    (files: File[]) => {
      localforage.setItem(taskCreationId, files).then(() => {
        const fileInfos = files.map(
          ({ name }, index) =>
            ({
              key: index.toString(),
              name
            } as FileInfo)
        );

        dispatch(setFiles({ id: taskCreationId, files: fileInfos }));
        fetchFiles(taskCreationId);
      });
    },
    [dispatch, fetchFiles, taskCreationId]
  );

  const createTask = useCallback(
    async (event: MouseEvent<HTMLButtonElement>) => {
      event.stopPropagation();
      if (!task) return;

      let hasErrors = false;
      const baseErrors = validateBaseFields(task);
      dispatch(updateTask({ id: taskCreationId, task: { baseErrors } }));
      const dynamicFieldsErrors = task.components?.some((c) => c.attributes?.some((a) => !validateDynamicField(a)));
      dispatch(updateTask({ id: taskCreationId, task: { dynamicFieldsErrors } }));
      hasErrors = dynamicFieldsErrors || Object.values(baseErrors).some((value) => value);

      if (task.customerAppointment) {
        const appointmentErrors = validateCustomerAppointment(task.customerAppointment);
        dispatch(updateTask({ id: taskCreationId, task: { appointmentErrors } }));
        hasErrors = hasErrors || Object.values(appointmentErrors).some((value) => value);
      }

      if (hasErrors) {
        NotificationService.error('Du mangler at udfylde et felt');
        return;
      }

      const openNearbyTasksOnSameAsset = task.nearbyTasks?.filter(
        (x) => x.status !== WorkTaskStatus.Processed && x.taskType?.id === task?.taskType?.id && x.assetId === task.assetId
      );

      if (openNearbyTasksOnSameAsset && openNearbyTasksOnSameAsset.length > 0) {
        const confirmation = await getConfirmation(getConfirmationDialog(task.taskType?.name ?? '', task.assetId ?? ''));
        if (confirmation !== 'confirm') {
          return;
        }
      }

      let taskObj = {
        taskLocation: task.taskLocation,
        description: task.description,
        appointmentTimeStart: task.appointmentTimeStart,
        appointmentTimeEnd: task.appointmentTimeEnd,
        status: task.status,
        createdDateTime: task.createdDateTime,
        createdByDepartmentId: userDepartment?.departmentId ?? planningDepartmentId,
        taskTypeId: task.taskTypeId,
        notesForPlanning: task.notesForPlanning,
        assignedToEmail: task.assignedToEmail,
        assignedToName: task.assignedToName,
        deadline: task.deadline,
        earliestStartDate: task.earliestStartDate,
        plannedArrival: task.plannedArrival,
        plannedDuration: task.plannedDuration,
        normTimeMin: task.normTimeMin,
        assignedToDepartmentId: task.assignedToDepartmentId,
        gisRegion: task.gisRegion,
        parentId: task.parentId,
        customerAppointment: task.customerAppointment,
        projectNumber: task.projectNumber,
        projectLegalEntityId: task.projectLegalEntityId,
        componentValues: task.componentValues,
        assetId: task.assetId,
        assetType: task.assetType,
        assetPrimaryVoltage: task.assetPrimaryVoltage,
        sendToPlanningOnCreation: task.sendToPlanningOnCreation,
        notesForTechnician: task.notesForTechnician,
        stretches: task.stretches,
        ignoreDrivingTimeWhenPlanned: task.ignoreDrivingTimeWhenPlanned
      } as CreateWorkTaskDTO2;

      setIsLoading(true);
      if (taskObj.taskLocation == null) {
        taskObj.taskLocation = task.assetLocation;
      }

      if (task.components) taskObj.componentValues = extractAttributeValuesFromComponents(task.components);

      Promise.resolve(TaskService.createTask(taskObj))
        .then((result) => {
          const workTaskId = parseInt(result?.id ?? '');
          if (workTaskId && files?.length) {
            let attachmentsPromises = files.map((file, i) => {
              if (!fileErrors.get(i)) {
                return AttachmentService.postAttachment(workTaskId, file);
              }
              return null;
            });
            Promise.all(attachmentsPromises)
              .then((res) => {
                endCreation(workTaskId);
              })
              .catch((error) => {
                log(error);
                NotificationService.error('Opgaven blev oprettet, men der opstod en fejl ved upload af filer');
                //TODO: Error handling when we can create small popup messages
              });
          } else {
            endCreation(workTaskId);
          }
        })
        .catch((error) => {
          setWarningMessage('Der opstod en fejl i oprettelsen.');
        })
        .finally(() => {
          setIsLoading(false);
        });
    },
    [task, dispatch, taskCreationId, userDepartment?.departmentId, getConfirmation, files, fileErrors, endCreation]
  );

  useEffect(() => {
    if (!activeTasks.find((task) => task.taskCreationId === id)) {
      dispatch(newTask({ taskCreationId: id }));
    }

    // setSendToPlanningOnCreation(task?.taskType?.sendTaskToPlanningOnCreation);
  }, [dispatch, id, activeTasks, task?.taskType?.sendTaskToPlanningOnCreation]);

  useEffect(() => {
    document.getElementById('task-details-body')?.scrollTo(0, 0);
  }, [activePanelId]);

  if (!task) return null;

  return (
    <DialogView handleClose={handleCloseDialog}>
      <StyledDialogContent>
        <TabsVertical
          headerComponent={
            <CreateTaskDialogHeader
              activeStep={Number(activePanelId)}
              handleStepChange={(id: string) => setActivePanelId(id)}
            />
          }
          activePanelId={activePanelId}
          handleActivePanelIdChange={(id: string) => setActivePanelId(id)}
          onClose={handleCloseDialog}
          onMinimize={() => navigate(removeQueryParams(['id'], EDIT_TASK_ROUTE))}
          setEnableEmailNotification={() => setEnableEmailNotification(!enableEmailNotification)}
          enableEmailNotification={enableEmailNotification}
        >
          <LocationStep
            tabId="1"
            contentHeaderText={renderTabsHeader()}
            tabHeaderText="Asset"
            task={task}
            notification={task.baseErrors?.taskLocation ? <WarningIcon color="red" size="18px" /> : undefined}
            footerComponent={
              <Footer>
                <Button onClick={() => setActivePanelId('2')}>Næste</Button>
              </Footer>
            }
          />
          <TaskTypeStep
            tabId="2"
            contentHeaderText={renderTabsHeader()}
            footerComponent={
              <Footer>
                <Button onClick={() => setActivePanelId('3')}>Næste</Button>
              </Footer>
            }
            tabHeaderText="Arbejdstype"
            task={task}
            notification={task.baseErrors?.taskTypeId ? <WarningIcon color="red" size="18px" /> : undefined}
            setShouldCreateRelationsTask={(createTask) => setShouldCreateRelationalTask(createTask)}
          />
          <DetailsStep
            tabId="3"
            files={files ?? []}
            handleFileChanges={handleFileChanges}
            contentHeaderText={renderTabsHeader()}
            handleGoToAsset={() => setActivePanelId('1')}
            simulatePlanning={shouldSimulatePlanning}
            setSimulatePlanning={setShouldSimulatePlanning}
            footerComponent={
              <Footer>
                {warningMessage && (
                  <Alert severity="error" height="42px">
                    {warningMessage}
                  </Alert>
                )}
                {task.taskType?.subWorkTaskType === SubWorkTaskType.LiveVoltageTask && (
                  <Alert margin="0px 10px" severity="warning" height="42px">
                    {'Bemærk: Denne opgave får automatisk tilknyttet en LAUS-mand.'}
                  </Alert>
                )}
                {task.taskType?.subWorkTaskType === SubWorkTaskType.SameAsMainTask && (
                  <Alert margin="0px 10px" severity="warning" height="42px">
                    {'Bemærk: Denne opgave får automatisk tilknyttet en ekstra tekniker.'}
                  </Alert>
                )}
                <CreateRelationalTaskCheckbox
                  label="Opret relationsopgave"
                  checked={shouldCreateRelationalTask}
                  onChange={(e) => setShouldCreateRelationalTask(e.target.checked)}
                  helpertext={
                    !!task.taskType?.defaultRelationalTaskTypeId
                      ? `Når du opretter denne opgave, opretter du samtidigt ${
                          task.taskTypePickerInfo?.taskTypeDTOs?.find(
                            (t) => t.id === task.taskType?.defaultRelationalTaskTypeId
                          )?.name
                        } som relationsopgave. Du skal selv justere tid`
                      : 'Når du opretter denne opgave, opretter du samtidigt en relationsopgave. Du skal selv vælge opgavetype og justere tid'
                  }
                />
                <CreateTaskButton isLoading={isLoading} onClick={createTask} data-testid="confirm-task-button">
                  Opret opgave
                </CreateTaskButton>
              </Footer>
            }
            onFileError={(error) => setFileErrors(error)}
            tabHeaderText="Detaljer"
            task={task}
          />
        </TabsVertical>
      </StyledDialogContent>
    </DialogView>
  );
};

const dialogBody: DialogBody = {
  headerText: 'Vil du slette oprettelse af nyt arbejdskort?',
  bodyText: 'Bemærk, hvis du sletter arbejdskortet, fjernes alle indtastede værdier',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft sletning'
};

const StyledDialogContent = styled.div`
  && {
    padding: 0;
    height: 85vh;
    width: 85vw;
    max-width: 1400px;
    overflow: hidden;
  }
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const CreateRelationalTaskCheckbox = styled(Checkbox)`
  display: flex;
  justify-content: flex-end;
  margin-right: 10px;
`;

const CreateTaskButton = styled(Button)`
  margin-left: 20px;
`;

export default CreateTaskDialog;
