import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import styled from 'styled-components';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import Typography from '../../components/typography/Typography';
import n1LightTheme from '../../theme/light-theme';
import {
  addTimeRegistration,
  editTimeRegistration,
  TimeRegistrationSO
} from '../../stateManagement/reducers/timeRegistrationReducer';
import Button from '../../components/button/Button';
import WeekDatePicker from '../../components/week-date-picker/WeekDatePicker';
import TimeRegDayHeader from './time-reg-day-header/TimeRegDayHeader';
import TimeRegTableUser from '../../blocks/time-registration-list/TimeRegTableUser';
import { AccordionSummaryStyle, TimeRegViewLayoutProps } from './TimeRegViewShell';
import { TIME_ADD_APP_ROUTE } from '../../utils/constants';
import { useDispatch } from 'react-redux';
import { useNavigate } from '../../hooks/useNavigate';
import ConfirmDialog from '../../components/confirm-dialog/ConfirmDialog';
import AppViewContainer from '../../components/app-view/AppView';

const TimeRegViewApp = (props: TimeRegViewLayoutProps) => {
  const {
    sumOfApprovedHours,
    registrationsForDay,
    isLoading,
    expandedDays,
    toggleDayExpansion,
    handleDeleteRegistration,
    currentWeekDays,
    setCurrentWeekDays,
    errors,
    approving,
    handleApproveDay
  } = props;

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOpenRegistration = (timeReg?: TimeRegistrationSO) => {
    if (timeReg) {
      dispatch(editTimeRegistration(timeReg ?? {}));
    } else {
      dispatch(addTimeRegistration({}));
    }
    navigate('/' + TIME_ADD_APP_ROUTE);
  };

  return (
    <AppViewContainer
      header={
        <>
          <Button onClick={() => handleOpenRegistration()}>Tilføj Timer</Button>
          <Typography variant="h5" marginBottom={0}>
            <b>{sumOfApprovedHours}</b> godkendte timer
          </Typography>
        </>
      }
      footer={<WeekDatePicker currentWeekDays={currentWeekDays} onDateChange={setCurrentWeekDays} renderTop />}
    >
      <ConfirmDialog />
      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          registrationsForDay.map((r) => {
            return (
              <Accordion
                disableGutters
                sx={{ '&:before': { display: 'none' } }}
                expanded={expandedDays.includes(r.day)}
                key={r.day}
              >
                <AccordionSummary sx={AccordionSummaryStyle} onClick={() => toggleDayExpansion(r.day)}>
                  <TimeRegDayHeader registrationsForDay={r} compact />
                </AccordionSummary>
                <AccordionDetails sx={{ border: `1px solid ${n1LightTheme.palette.grey.black10}` }}>
                  <StyledAccordionDetailsDiv>
                    <TimeRegTableUser
                      handleEdit={(timeReg: TimeRegistrationSO) => handleOpenRegistration(timeReg)}
                      handleDelete={(id) => handleDeleteRegistration(id)}
                      timeRegistrations={r.registrations}
                      errors={errors}
                      compact
                    />
                    {r.registrations.length > 0 && (
                      <ButtonContainer>
                        <StyledButton
                          disabled={!r.registrations.some((reg) => !reg.approvedByUser)}
                          onClick={() => handleApproveDay(r.registrations)}
                          data-testid="confirm-hours"
                        >
                          {approving ? <CircularProgress /> : <div>Godkend</div>}
                        </StyledButton>
                      </ButtonContainer>
                    )}
                  </StyledAccordionDetailsDiv>
                </AccordionDetails>
              </Accordion>
            );
          })
        )}
      </div>
    </AppViewContainer>
  );
};

const StyledAccordionDetailsDiv = styled.div`
  padding: 0px 16px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const StyledButton = styled(Button)`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(props) => props.theme.spacing(2)};
`;

export default TimeRegViewApp;
