import { useState, useEffect } from 'react';
import addDays from 'date-fns/addDays';
import { useLocation, useMatch } from 'react-router-dom';
import { ApiException, DetailedTimeRegistrationDTO } from '../../api/api';
import { TimeRegError } from '../../blocks/time-registration-list/TimeRegTableUser';
import TimeRegistrationService from '../../services/TimeRegistrationService';
import n1LightTheme from '../../theme/light-theme';
import { getCurrentWeek } from '../../utils/dateHandling';
import { DialogBody } from '../../stateManagement/reducers/confirmDialogReducer';
import { useConfirmationDialog } from '../../hooks/useConfirmationDialog';
import NotificationService from '../../services/NotificationService';
import { ErrorCodes, TIME_APP_ROUTE, TIME_ROUTE } from '../../utils/constants';
import { log } from '../../utils/logging/log';
import TimeRegViewApp from './TimeRegViewApp';
import TimeRegViewDefault from './TimeRegViewDefault';

export interface TimeRegViewLayoutProps {
  sumOfApprovedHours: number;
  registrationsForDay: RegistrationsCollectionForDay[];
  isLoading: boolean;
  expandedDays: number[];
  toggleDayExpansion: (day: number) => void;
  handleDeleteRegistration: (id: number) => void;
  currentWeekDays: { start: Date; end: Date; week: number };
  setCurrentWeekDays: React.Dispatch<
    React.SetStateAction<{
      start: Date;
      end: Date;
      week: number;
    }>
  >;
  errors: TimeRegError[];
  approving: boolean;
  handleApproveDay: (registrations: DetailedTimeRegistrationDTO[]) => void;
}

export const AccordionSummaryStyle = {
  backgroundColor: n1LightTheme.palette.grey.black5,
  border: `1px solid ${n1LightTheme.palette.grey.black10}`
};

const dialogBodyApprove: DialogBody = {
  headerText: '',
  bodyText: 'Vil du godkende timer for den valgte dag?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

const dialogBodyDelete: DialogBody = {
  headerText: '',
  bodyText: 'Vil du slette den valgte tidsregistrering?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

export interface RegistrationsCollectionForDay {
  day: number;
  hoursTotal: number;
  hoursApproved: number;
  registrations: DetailedTimeRegistrationDTO[];
  date: string;
  registrationsNotApproved: number;
}

const dayComparer = (a: RegistrationsCollectionForDay, b: RegistrationsCollectionForDay) => {
  if (a.day === 0 && b.day !== 0) return 1;
  if (a.day !== 0 && b.day === 0) return -1;
  if (a.day > b.day) return 1;
  if (a.day < b.day) return -1;
  return 0;
};

const getInitialRegistrationCollection = (from: Date): RegistrationsCollectionForDay[] => {
  const list: RegistrationsCollectionForDay[] = [];
  for (let i = 0; i < 7; i++) {
    const date = addDays(from, i);
    list.push({
      date: date.toString(),
      day: date.getDay(),
      hoursApproved: 0,
      hoursTotal: 0,
      registrations: [],
      registrationsNotApproved: 0
    });
  }
  return list;
};

const TimeRegViewShell = () => {
  const [registrationsForDay, setRegistrationsForDay] = useState<RegistrationsCollectionForDay[]>([]);
  const [expandedDays, setExpandedDays] = useState<number[]>([]);
  const [currentWeekDays, setCurrentWeekDays] = useState<{ start: Date; end: Date; week: number }>(getCurrentWeek());
  const [sumOfApprovedHours, setSumOfApprovedHours] = useState(0);
  const [approving, setApproving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<TimeRegError[]>([]);

  const { getConfirmation } = useConfirmationDialog();

  const location = useLocation();

  const matchTaskRoute = useMatch(TIME_ROUTE);

  useEffect(() => {
    if (matchTaskRoute) {
      fetchRegistrations();
    }
  }, [location]);

  useEffect(() => {
    fetchRegistrations();
    setExpandedDays([]);
  }, [currentWeekDays]);

  const fetchRegistrations = () => {
    setIsLoading(true);
    const { start, end } = currentWeekDays;
    TimeRegistrationService.getTimeRegistrationsForPeriod(start.toISOString(), end.toISOString())
      .then((res) => {
        setIsLoading(false);
        const tmpRegistrationsCollectionForDay: RegistrationsCollectionForDay[] = getInitialRegistrationCollection(start);
        let tmpSumOfApprovedHours = 0;
        res
          .filter((t) => !t.isDeleted && t.relatedToLineId === 0)
          .forEach((r: DetailedTimeRegistrationDTO) => {
            if (!r.date) return;
            const hours = r.hours ?? 0;
            let day = new Date(r.date).getDay();
            const registrationCollectionForDay = tmpRegistrationsCollectionForDay.find(
              (registration: RegistrationsCollectionForDay) => registration.day === day
            );
            if (registrationCollectionForDay) {
              registrationCollectionForDay.registrations.push(r);
              registrationCollectionForDay.hoursTotal += hours;
              registrationCollectionForDay.hoursApproved += r.approvedByUser ? hours : 0;
              !r.approvedByUser && registrationCollectionForDay.registrationsNotApproved++;
            } else {
              tmpRegistrationsCollectionForDay.push({
                day,
                registrations: [r],
                hoursTotal: hours,
                hoursApproved: hours,
                date: r.date,
                registrationsNotApproved: r.approvedByUser ? 0 : 1
              });
            }
            tmpSumOfApprovedHours += r.approvedByUser ? hours : 0;
          });

        tmpRegistrationsCollectionForDay.sort((a, b) => dayComparer(a, b));
        setRegistrationsForDay(tmpRegistrationsCollectionForDay);
        setSumOfApprovedHours(tmpSumOfApprovedHours);
        if (
          tmpRegistrationsCollectionForDay.some(
            (x) => new Date(x.date).toDateString() === new Date().toDateString() && expandedDays.length === 0
          )
        ) {
          setExpandedDays([new Date().getDay()]);
        }
      })
      .catch((err) => {
        log(err);
        setIsLoading(false);
      });
  };

  const toggleDayExpansion = (day: number) => {
    const index = expandedDays.findIndex((e) => e === day);
    const tmpExpandedDays = [...expandedDays];
    if (index < 0) {
      tmpExpandedDays.push(day);
    } else {
      tmpExpandedDays.splice(index, 1);
    }
    setExpandedDays(tmpExpandedDays);
  };

  const handleApproveDay = async (registrations: DetailedTimeRegistrationDTO[]) => {
    const confirmResult = await getConfirmation(dialogBodyApprove);

    if (confirmResult === 'confirm') {
      setApproving(true);
      const ids = registrations
        .map((r) => (!r.approvedByUser && !r.isSentToErp ? r.id : undefined))
        .filter(Boolean) as number[];

      if (ids.length < 1) {
        return;
      }

      TimeRegistrationService.approveTimeRegistrations(ids)
        .then(() => {
          NotificationService.success('Tiden blev godkendt');
        })
        .catch((err: ApiException) => {
          const error = JSON.parse(err.response);
          if (error.type === ErrorCodes.ERP_CALL_ERROR) {
            NotificationService.error('Der opstod en ERP fejl ved godkendelsen.');
            setErrors(
              error.ProjectIds.map((id: number) => ({
                id,
                errorMsg:
                  'Vi kan ikke komme i kontakt med ERP systemet. Prøv igen senere eller kontakt supporten hvis problemet fortsætter.'
              }))
            );
          } else if (error.type === ErrorCodes.PROJECT_VALIDATION_ERROR) {
            NotificationService.error('En eller flere tidsregistreringer kunne godkendes.');
            setErrors(
              error.ProjectIds.map((id: number) => ({
                id,
                errorMsg:
                  'Projektnummeret er i et forkert stadie til tidsregistrering. Kontakt opgavestiller for at få opdateret projektnummeret.'
              }))
            );
          } else {
            NotificationService.error('Der opstod en ukendt fejl');
          }
        })
        .finally(() => {
          fetchRegistrations();
          setApproving(false);
        });
    }
  };

  const handleDeleteRegistration = async (regId: number) => {
    const confirmed = await getConfirmation(dialogBodyDelete);

    if (confirmed === 'confirm') {
      setIsLoading(true);
      TimeRegistrationService.deleteTimeRegistration(regId)
        .then(() => {
          fetchRegistrations();
          NotificationService.success(`Timeregistrering blev slettet`);
          setIsLoading(false);
        })
        .catch((error) => {
          log(error);
          NotificationService.error(`Kunne ikke slette timeregistrering ${regId}: ${error}`);
          setIsLoading(false);
        });
    }
  };

  if (location.pathname.includes(TIME_APP_ROUTE)) {
    return (
      <TimeRegViewApp
        sumOfApprovedHours={sumOfApprovedHours}
        registrationsForDay={registrationsForDay}
        isLoading={isLoading}
        expandedDays={expandedDays}
        toggleDayExpansion={toggleDayExpansion}
        handleDeleteRegistration={handleDeleteRegistration}
        currentWeekDays={currentWeekDays}
        setCurrentWeekDays={setCurrentWeekDays}
        errors={errors}
        approving={approving}
        handleApproveDay={handleApproveDay}
      />
    );
  }

  return (
    <TimeRegViewDefault
      sumOfApprovedHours={sumOfApprovedHours}
      registrationsForDay={registrationsForDay}
      isLoading={isLoading}
      expandedDays={expandedDays}
      toggleDayExpansion={toggleDayExpansion}
      handleDeleteRegistration={handleDeleteRegistration}
      currentWeekDays={currentWeekDays}
      setCurrentWeekDays={setCurrentWeekDays}
      errors={errors}
      approving={approving}
      handleApproveDay={handleApproveDay}
    />
  );
};

export default TimeRegViewShell;
