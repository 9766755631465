import { Dialog, DialogContent, DialogTitle, List, ListItem, Typography } from '@mui/material';
import BarcodeScanner from '../../components/barcode-scanner/barcode-scanner';
import { useState } from 'react';
import OrdersService from '../../services/OrdersService';
import NotificationService from '../../services/NotificationService';
import { ConfirmOrderReceivedDTO } from '../../api/api';
import { formatDateStringISO } from '../../utils/dateHandling';
import Button from '../../components/button/Button';

export const BarcodeScannerComponent = () => {
  const [code, setCode] = useState('');
  const [status, setStatus] = useState<'initial' | 'success' | 'error'>('initial');
  const [response, setResponse] = useState<ConfirmOrderReceivedDTO>();

  const confirm = (code: string) => {
    console.log('Sender..', code);
    OrdersService.createReturnOrder(code)
      .then((res) => {
        setResponse(res);
        setCode(code);
        NotificationService.success('Done');
        setStatus('success');
      })
      .catch(() => {
        NotificationService.error('Fejl');
      });
  };

  return (
    <>
      <div style={{ height: '100%', width: '100%' }}>
        <BarcodeScanner codeFoundCallback={(code) => confirm(code)} handleClose={() => {}} />
        {status === 'initial' && <Typography sx={{ marginTop: '20px' }}>Klar til aflæsning</Typography>}
        {status === 'success' && (
          <>
            <Dialog onClose={() => setStatus('initial')} open={status === 'success'}>
              <DialogTitle textAlign="center">Kvittering</DialogTitle>
              <DialogContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <List sx={{ pt: 0 }}>
                  <ListItem>
                    <Typography marginRight={'10px'} fontWeight={'bold'} variant="subtitle2">
                      Transfer ordre ID:
                    </Typography>
                    <Typography variant="subtitle2">{response?.transferOrderId}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography marginRight={'10px'} fontWeight={'bold'} variant="subtitle2">
                      Worktask ID:
                    </Typography>
                    <Typography variant="subtitle2"> {response?.worktaskId}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography marginRight={'10px'} fontWeight={'bold'} variant="subtitle2">
                      Dato:
                    </Typography>
                    <Typography variant="subtitle2">{formatDateStringISO(response?.confirmedReceiveDate ?? '')}</Typography>
                  </ListItem>
                  <ListItem>
                    <Typography marginRight={'10px'} fontWeight={'bold'} variant="subtitle2">
                      Bestilt af:
                    </Typography>
                    <Typography variant="subtitle2">{response?.orderedBy}</Typography>
                  </ListItem>
                  <ListItem sx={{ display: 'flex', alignItems: 'start' }}>
                    <Typography marginRight={'10px'} fontWeight={'bold'} variant="subtitle2">
                      Beskrivelse:
                    </Typography>
                    <Typography variant="subtitle2">{response?.orderDescription}</Typography>
                  </ListItem>
                </List>
                <Button
                  onClick={() => {
                    setCode('');
                    setStatus('initial');
                  }}
                >
                  Luk
                </Button>
              </DialogContent>
            </Dialog>
          </>
        )}

        {status === 'error' && (
          <Typography sx={{ marginTop: '20px' }}>{`Fejl: Kunne ikke bekræfte koden ${code}`}</Typography>
        )}
      </div>
    </>
  );
};

export default BarcodeScannerComponent;
