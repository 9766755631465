import { useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';

import Table from '../../../blocks/table/TableClient';
import Typography from '../../../components/typography/Typography';
import { SelectedGoods, DeliveryLocation } from '../../../models/Goods';
import { useSelectedGoodsList } from '../hooks/useSelectedGoodsList';
import { DeleteIcon } from '../../../assets/icons/DeleteIcon';
import { DialogBody } from '../../../stateManagement/reducers/confirmDialogReducer';
import Button from '../../../components/button/Button';
import { useConfirmationDialog } from '../../../hooks/useConfirmationDialog';
import AutoComplete from '../../../components/auto-complete/AutoComplete';
import TextField from '../../../components/text-field/TextField';
import { DropPointDTO, LocationDTO } from '../../../api/api';
import { FormControl, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import WarehouseService from '../../../services/WarehouseService';
import { log } from '../../../utils/logging/log';
import NotificationService from '../../../services/NotificationService';

const clearGoodsDialog: DialogBody = {
  headerText: 'Vil du fjerne alle varer i din kurv?',
  bodyText: 'Du er ved at fjerne alle varer fra din kurv. Er du sikker på, at du vil fortsætte?',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

interface Props {
  selectedGoods: SelectedGoods[];
  setGoodsAmount: (value: number, goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  removeGoods?: (goodsId?: string, goodsBatch?: string, goodsSerial?: string) => void;
  className?: string;
  showAvailable?: boolean;
  showDeliverTo?: boolean;
  selectedDeliveryLocation?: DeliveryLocation;
  setDeliveryLocation?: (delivery: DeliveryLocation) => void;
  deliveryOptions?: DeliveryLocation[];
  clearSelectedGoods?: () => void;
  renderFullHeight?: boolean;
  taskLocation?: LocationDTO;
}

enum DropPointOptions {
  NEAREST = 'NEAREST',
  SPECIFIC = 'SPECIFIC'
}

const SelectedGoodsList = (props: Props) => {
  const {
    selectedGoods,
    className,
    removeGoods,
    setGoodsAmount,
    showAvailable = true,
    selectedDeliveryLocation,
    setDeliveryLocation,
    deliveryOptions,
    clearSelectedGoods,
    renderFullHeight = false,
    taskLocation
  } = props;

  const { tableInstance } = useSelectedGoodsList(selectedGoods, setGoodsAmount, removeGoods, showAvailable);
  const { getConfirmation } = useConfirmationDialog();
  const [dropPointOption, setDropPointOption] = useState<DropPointOptions>(DropPointOptions.NEAREST);
  const [loading, setLoading] = useState(false);
  const [nearestDropPoint, setNearestDropPoint] = useState<DropPointDTO>();

  const sortedDeliveryOptions = useMemo(() => {
    return deliveryOptions?.sort((a, b) => a.name.toLocaleLowerCase().localeCompare(b.name.toLocaleLowerCase())) ?? [];
  }, [deliveryOptions]);

  const selectedCount = useMemo(() => {
    return selectedGoods.reduce((acc, goods) => acc + (goods?.qty ?? 1), 0);
  }, [selectedGoods]);

  useEffect(() => {
    getNearestDropPoint();
  }, [taskLocation]);

  useEffect(() => {
    if (dropPointOption === DropPointOptions.NEAREST && setDeliveryLocation && taskLocation) {
      setDeliveryLocation({ id: nearestDropPoint?.warehouseId ?? '', name: nearestDropPoint?.name ?? '' });
    }
  }, [dropPointOption, nearestDropPoint, setDeliveryLocation, taskLocation]);

  const getNearestDropPoint = () => {
    if (!taskLocation?.latitude || !taskLocation?.longitude) return;
    setLoading(true);

    WarehouseService.getDropPointsByDistance(taskLocation.latitude, taskLocation.longitude)
      .then((data) => {
        setNearestDropPoint(data[0]);
      })
      .catch((error) => {
        log(error);
        NotificationService.error('Kunne ikke hente nærmeste dropsted');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleClearSelectedGoods = () => {
    getConfirmation(clearGoodsDialog).then((res) => {
      if (res === 'confirm') {
        clearSelectedGoods && clearSelectedGoods();
      }
    });
  };

  return (
    <>
      <Header>
        {selectedDeliveryLocation && (
          <>
            <Typography variant="h5" fontWeight="bold">
              Varer sendes til:
            </Typography>
            {taskLocation ? (
              <FormControl>
                <RadioGroup
                  value={dropPointOption}
                  onChange={(e) => setDropPointOption(e.target.value as DropPointOptions)}
                  row
                  aria-labelledby="demo-row-radio-buttons-group-label"
                  name="row-radio-buttons-group"
                >
                  <NearestDropPointContainer>
                    <FormControlLabel
                      value={DropPointOptions.NEAREST}
                      control={<Radio />}
                      label={`Nærmeste dropsted (${loading ? 'Henter..' : nearestDropPoint?.city})`}
                    />
                  </NearestDropPointContainer>
                  <SpecificDropPointContainer>
                    <FormControlLabel
                      value={DropPointOptions.SPECIFIC}
                      control={<Radio />}
                      label={dropPointOption === DropPointOptions.NEAREST && 'Specifikt leveringssted'}
                    />
                    {dropPointOption === DropPointOptions.NEAREST ? (
                      ''
                    ) : (
                      <>
                        {sortedDeliveryOptions && setDeliveryLocation ? (
                          <AutoComplete
                            onChange={(_, value, reason) => setDeliveryLocation(value as DeliveryLocation)}
                            options={sortedDeliveryOptions}
                            value={selectedDeliveryLocation}
                            renderInput={(params) => <TextField {...params} label={'Specifikt leveringssted'} fullWidth />}
                            getOptionLabel={(option) => `${option.name}`}
                            isOptionEqualToValue={(option, value) => option.id === value.id}
                            noOptionsText={'Kan ikke hente dropsteder'}
                            disableClearable
                            sx={{ width: '250px' }}
                          />
                        ) : (
                          <Typography variant="h5" fontWeight="bold">
                            ({selectedDeliveryLocation.id}) {selectedDeliveryLocation.name}
                          </Typography>
                        )}
                      </>
                    )}
                  </SpecificDropPointContainer>
                </RadioGroup>
              </FormControl>
            ) : (
              <Typography variant="h5" fontWeight="bold">
                ({selectedDeliveryLocation.id}) {selectedDeliveryLocation.name}
              </Typography>
            )}
          </>
        )}
        {clearSelectedGoods && (
          <Button variant="secondary" onClick={handleClearSelectedGoods} disabled={selectedCount === 0}>
            <DeleteIcon size="20px" />
            Ryd kurv {selectedCount > 0 ? `(${selectedCount})` : ''}
          </Button>
        )}
      </Header>
      <StyledTable
        tableInstance={tableInstance}
        loading={false}
        alwaysShowSort
        noDataText="Ingen valgte varer. Vælg fra listen over mulige varer."
        className={className}
        renderFullHeight={renderFullHeight}
      />
    </>
  );
};

const StyledTable = styled(Table)`
  &.table-outer-container {
    margin: 0;
    padding: 0;
  }
  .table-content-container {
    margin: 0;
    padding: 0;
  }
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${(props) => props.theme.spacing(3)} 0;
`;

const NearestDropPointContainer = styled.div`
  margin-right: 50px;
`;

const SpecificDropPointContainer = styled.div`
  display: flex;
  width: 300px;
`;

export default SelectedGoodsList;
