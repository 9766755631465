import { useEffect } from 'react';
import { Html5Qrcode, Html5QrcodeSupportedFormats } from 'html5-qrcode';
import { IconButton } from '@mui/material';
import styled from 'styled-components';

const formatsToSupport = [Html5QrcodeSupportedFormats.CODE_39, Html5QrcodeSupportedFormats.CODE_128];

const waitForElement = (selector: string) => {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return resolve(document.querySelector(selector));
    }

    const observer = new MutationObserver((mutations) => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        resolve(document.querySelector(selector));
      }
    });

    observer.observe(document.body, {
      childList: true,
      subtree: true
    });
  });
};

interface Props {
  handleClose: () => void;
  codeFoundCallback: (code: string) => void;
}

const BarcodeScanner = (props: Props) => {
  const { handleClose, codeFoundCallback } = props;

  const startCamera = (scanner: Html5Qrcode) => {
    scanner.start(
      { facingMode: 'environment' },
      {
        fps: 30,
        qrbox: { height: 100, width: window.screen.width * 0.9 }
      },
      (decodedText) => {
        codeFoundCallback(decodedText);
      },
      (error) => {}
    );
  };

  useEffect(() => {
    let html5QrCode: Html5Qrcode;
    waitForElement('#reader').then(() => {
      html5QrCode = new Html5Qrcode('reader', {
        verbose: true,
        formatsToSupport
      });

      startCamera(html5QrCode);
    });

    return () => {
      html5QrCode.stop();
    };
  }, []);

  return <div id="reader" />;
};

const StyledIconButton = styled(IconButton)`
  position: absolute;
  z-index: 1;
  right: 0;
  margin: 20px;
`;

export default BarcodeScanner;
