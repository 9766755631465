import { addDays } from 'date-fns';
import { memo, useCallback } from 'react';
import styled from 'styled-components';

import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { ChevronRight } from '../../assets/icons/ChevronRight';
import { formatDateString, getCurrentWeek, WeekDays } from '../../utils/dateHandling';
import DatePicker from '../date-picker/DatePicker';
import IconButton from '../icon-button/IconButton';

interface Props {
  currentWeekDays: WeekDays;
  onDateChange: (weekdays: WeekDays) => void;
  renderTop?: boolean;
}

const WeekDatePicker = (props: Props) => {
  const { currentWeekDays, onDateChange, renderTop } = props;

  const handleDateChange = useCallback(
    (newDate: string | Date, daysToAdd: number = 0) => {
      const date = addDays(new Date(newDate), daysToAdd);
      onDateChange(getCurrentWeek(new Date(date)));
    },
    [onDateChange]
  );

  return (
    <StyledDatePickerContainer>
      <StyledArrowsContainer>
        <IconButton padding="10px" variant="outlined" onClick={() => handleDateChange(currentWeekDays.start, -7)}>
          <ChevronLeft strokeWidth="5" padding="0 0 0 3px" size="14px" />
        </IconButton>
        <DatePicker
          defaultDate={currentWeekDays.start}
          onDateChanged={(date) => handleDateChange(date.toISOString())}
          value={`${formatDateString(currentWeekDays.start.toString())} - ${formatDateString(
            currentWeekDays.end.toString()
          )}`}
          selectEntireWeek
          top={renderTop ? '-350px' : undefined}
        />
        <IconButton padding="10px" variant="outlined" onClick={() => handleDateChange(currentWeekDays.start, 7)}>
          <ChevronRight strokeWidth="5" padding="0 0 0 2px" size="14px" />
        </IconButton>
      </StyledArrowsContainer>
    </StyledDatePickerContainer>
  );
};

const StyledDatePickerContainer = styled.div`
  display: flex;
  column-gap: 0.75rem;
`;

const StyledArrowsContainer = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;
`;

export default memo(WeekDatePicker);
