import styled from 'styled-components';
import Typography from '../../../../../components/typography/Typography';
import { OrderDTO, OrderStatus } from '../../../../../api/api';
import { formatDateString, formatTimeAndDateString } from '../../../../../utils/dateHandling';
import { getInitialsFromEmail } from '../../../../../utils/initialsUtils';

import { useReceiptList } from './useReceiptList';
import { CheckmarkCircleIcon } from '../../../../../assets/icons/CheckmarkCircleIcon';
import n1LightTheme from '../../../../../theme/light-theme';
import Table from '../../../../../blocks/table/TableClient';

interface Props {
  order: OrderDTO;
}

const OrderReceiptContent = (props: Props) => {
  const { order } = props;

  const { tableInstance } = useReceiptList(order.items ?? []);

  return (
    <ReceiptContainer>
      <OrderDetails>
        <Detail>
          <Typography variant="h5">
            Oprettet: {''}
            <b>
              {formatTimeAndDateString(order.created ?? '')} ({getInitialsFromEmail(order.createdByEmail ?? '')})
            </b>
          </Typography>
        </Detail>
        <Detail>
          <Typography variant="h5">
            Bestilt til: {''}
            <b>
              {order.toWarehouseName} ({order.toWarehouseId})
            </b>
          </Typography>
        </Detail>
      </OrderDetails>

      <TableContainer>
        <Table
          loading={false}
          tableInstance={tableInstance}
          noPadding
          noDataText={
            order.orderStatus === OrderStatus.Created
              ? 'Bestilling er sendt til D365. Du vil kunne se dine varer her, når bestillingen er gået igennem'
              : ''
          }
        />
      </TableContainer>

      <FooterContainer>
        <FooterDetails>
          <Detail>
            <Typography>Forventet leveringsdato</Typography>
            <Typography fontWeight="bold">{formatDateString(order.additionalReceiptDate ?? '')}</Typography>
          </Detail>
          <Detail>
            <Typography color="greyedOut">Ønsket leveringsdato</Typography>
            <Typography fontWeight="bold" color="greyedOut">
              {formatDateString(order.requestedReceiptDate ?? '')}
            </Typography>
          </Detail>
          <Detail>
            <Typography>Besked på pakken</Typography>
            <Typography fontWeight="bold">{order.description}</Typography>
          </Detail>
        </FooterDetails>
      </FooterContainer>
    </ReceiptContainer>
  );
};

const ReceiptContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - 60px);
  align-items: flex-start;
  padding: 0 ${(props) => props.theme.spacing(7.5)} ${(props) => props.theme.spacing(7.5)}
    ${(props) => props.theme.spacing(7.5)};
  background-color: ${(props) => props.theme.palette.background.primary};
  border-radius: ${(props) => props.theme.spacing(3)};
`;

const OrderDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: ${(props) => props.theme.spacing(5)};
`;

const Detail = styled.div`
  flex-direction: column;
  margin-right: ${(props) => props.theme.spacing(5)};
  width: 50%;
  text-align: left;
  :last-child {
    margin-right: 0;
    text-align: right;
  }
`;

const TableContainer = styled.div`
  flex: 1;
  width: 100%;
  margin-top: ${(props) => props.theme.spacing(7)};
  overflow-y: auto;
`;

const MovedToCar = styled.div`
  margin-left: ${(props) => props.theme.spacing(2)};
`;

const FooterContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const FooterDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding-bottom: ${(props) => props.theme.spacing(3)};
`;

const Footer = styled.div`
  padding-top: ${(props) => props.theme.spacing(3)};
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;

export default OrderReceiptContent;
