import { useCallback, useState } from 'react';
import styled from 'styled-components';
import DialogView from '../../../components/dialog-view/DialogView';
import OrderGoodsContent, { OrderGoodsPage } from './OrderGoodsContent';
import { isDevice } from '../../../utils/device-handling/deviceDetectionUtils';
import { useNavigate } from '../../../hooks/useNavigate';
import NavigationHeader from '../components/NavigationHeader';

interface Props {
  onClose?: () => void;
}

const OrderGoodsView = (props: Props) => {
  const { onClose } = props;
  const [page, setPage] = useState<OrderGoodsPage>(OrderGoodsPage.SELECT);

  const fullscreenTask = isDevice();
  const navigate = useNavigate();

  const handleCloseDialog = useCallback(() => {
    onClose && onClose();
  }, [onClose]);

  const handleCloseTask = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const header = (handleOnClose: () => void) => {
    return page === OrderGoodsPage.SELECT ? (
      <NavigationHeader headerTitle={'Bestil varer'} onClose={handleOnClose} />
    ) : (
      <NavigationHeader
        headerTitle={'Varekurv'}
        onBackButton={() => setPage(OrderGoodsPage.SELECT)}
        onClose={handleOnClose}
      />
    );
  };

  return (
    <>
      {fullscreenTask ? (
        <StyledContent>
          {header(handleCloseTask)}
          <OrderGoodsContent orderToUserVeicle={true} onClose={handleCloseTask} page={page} setPage={setPage} />
        </StyledContent>
      ) : (
        <DialogView handleClose={handleCloseDialog}>
          <StyledDialogContent>
            {header(handleCloseDialog)}
            <OrderGoodsContent orderToUserVeicle={true} onClose={handleCloseDialog} page={page} setPage={setPage} />
          </StyledDialogContent>
        </DialogView>
      )}
    </>
  );
};

const StyledDialogContent = styled.div`
  padding: 0;
  height: 90vh;
  width: 85vw;
  max-width: 1400px;
  overflow: hidden;

  @media (max-width: 1200px) {
    width: 95vw;
  }
`;

const StyledContent = styled.div`
  height: 100vh;
  width: 100vw;

  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: start;
  overflow: hidden;
`;

export default OrderGoodsView;
