import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useDispatch, useSelector } from 'react-redux';
import {
  selectSearchStatuses,
  setAssetSearchStatus,
  updateTask
} from '../../../stateManagement/reducers/taskCreationReducer';

import LocationSearch from './location-search/LocationSearch';
import MapComponent, { MapMarker } from '../../../components/map-component/MapComponent';
import AddressAutocomplete from '../../../components/address-autocomplete/AddressAutocomplete';
import { ColumnFlex2 } from '../../../styling/Styling';
import { getNearestAddressByLatLng } from '../../../utils/location/locationHandling';
import { LocationDTO, AssetResultDTO, AssetType2, AccessConditionsDTO, GisRegion2 } from '../../../api/api';
import { TaskCreationTask } from '../../../models/TaskCreationTask';
import NearbyTasks from '../../../blocks/nearby-tasks/NearbyTasks';
import { TabProps } from '../../../blocks/tabs-vertical/TabsVertical';
import { Container, Section, SectionHeader } from '../../../styling/FormStyling';
import Checkbox from '../../../components/checkbox/Checkbox';
import { log } from '../../../utils/logging/log';
import TextRow from '../../task-view/task-details-view2/components/TextRow';
import Button from '../../../components/button/Button';
import AccessConditionDialog from '../../../blocks/access-conditions-dialog/AccessConditionDialog';
import NotificationService from '../../../services/NotificationService';
import { Coordinates } from '../../../models/Coordinates';
import AssetService from '../../../services/AssetService';

interface Props extends TabProps {
  task: TaskCreationTask;
}

const LocationStep = (props: Props) => {
  const { task } = props;
  const { taskCreationId = '' } = task;

  const dispatch = useDispatch();
  const searchStatuses = useSelector(selectSearchStatuses);
  const searchStatus = searchStatuses.find((x) => x.taskCreationId === taskCreationId);
  const initialSelectedAssetLocation = searchStatus?.locations?.find((x) => x.assetId === task?.assetId);
  const initialX = !!task?.taskLocation ? task?.taskLocation?.longitude : task?.assetLocation?.longitude;
  const initialY = !!task?.taskLocation ? task?.taskLocation?.latitude : task?.assetLocation?.latitude;

  const [asset, setAsset] = useState<AssetResultDTO | undefined>(initialSelectedAssetLocation);
  const [taskLocation, setTaskLocation] = useState<LocationDTO>(task?.taskLocation ?? {});

  const { locations: assetLocations, selectedLocation, showSelectTaskLocation } = searchStatus ?? {};

  const [accessConditionIsLoading, setAccessConditionIsLoading] = useState(false);

  const [coordinates, setCoordinates] = useState<Coordinates | undefined>({
    longitude: initialX ?? 0,
    latitude: initialY ?? 0
  });

  const [markers, setMarkers] = useState<MapMarker[]>(
    coordinates?.latitude !== 0 || coordinates.longitude !== 0
      ? [{ markerType: 'Main', lat: coordinates?.latitude ?? 0, lng: coordinates?.longitude ?? 0, key: 'TaskLocation' }]
      : []
  );
  const [assetId, setAssetId] = useState<string>('');
  const [accessConditions, setAccessConditions] = useState<AccessConditionsDTO>();
  const [showAccessConditionsDialog, setShowAccessConditionsDialog] = useState(false);

  useEffect(() => {
    if (task.assetId && task.assetType) {
      setAccessConditionIsLoading(true);
      AssetService.getAssets(task.assetId, task.assetType)
        .then((res) => {
          const assetResultDto: AssetResultDTO[] = res;
          setAccessConditions(assetResultDto[0].accessConditions);
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke hente adgangsforhold fra GIS.', 5000);
        })
        .finally(() => {
          setAccessConditionIsLoading(false);
        });
    }
  }, [task.assetId, task.assetType]);

  const handleTaskLocationSelected = useCallback(
    (location: LocationDTO) => {
      setTaskLocation(location);
      const { longitude = undefined, latitude = undefined } = location;
      if (!!longitude && longitude !== 0 && !!latitude && latitude !== 0) {
        setMarkers([{ markerType: 'Main', lat: latitude ?? 0, lng: longitude ?? 0, key: 'TaskLocation' }]);
        dispatch(updateTask({ id: taskCreationId, task: { taskLocation: location } }));
      }
    },
    [dispatch, taskCreationId]
  );

  const handleAssetLocationSelected = (assetResult: AssetResultDTO) => {
    setAsset(assetResult);
    setAssetId(assetResult.assetId ?? '');
    dispatch(updateTask({ id: taskCreationId, task: { accessConditions: assetResult.accessConditions } }));
    const { longitude = undefined, latitude = undefined } = assetResult?.location ?? {};
    if (!!longitude && longitude !== 0 && !!latitude && latitude !== 0) {
      setCoordinates({ longitude, latitude });
      setMarkers([{ markerType: 'Main', lat: latitude ?? 0, lng: longitude ?? 0, key: 'AssetLocation' }]);
      const newTask: TaskCreationTask = {
        assetLocation: assetResult?.location,
        taskLocation: assetResult?.location,
        assetId: assetResult?.assetId,
        assetType: assetResult?.assetType,
        assetPrimaryVoltage: assetResult?.primaryVoltage,
        gisRegion: assetResult?.gisRegion as unknown as GisRegion2
      };
      dispatch(updateTask({ id: taskCreationId, task: newTask }));
    }
  };

  const handleAssetLocationsReceived = (_asset: AssetResultDTO) => {
    handleAssetLocationSelected(_asset);
    dispatch(
      setAssetSearchStatus({
        taskCreationId,
        category: _asset.assetType,
        locations: [_asset],
        assetSearchTerm: _asset.assetId
      })
    );
  };

  const handleMapClick = useCallback(
    async (e: any) => {
      const lat = e.latLng.lat();
      const lng = e.latLng.lng();
      setCoordinates({ longitude: lng, latitude: lat });
      handleTaskLocationSelected({ ...taskLocation, ...{ latitude: lat, longitude: lng } });
      getNearestAddressByLatLng([{ lat, lng }])
        .then((result) => {
          if (!!result && result.length > 0) {
            dispatch(
              updateTask({
                id: taskCreationId,
                task: { taskLocation: result[0] }
              })
            );
            dispatch(
              setAssetSearchStatus({
                selectedLocation: result[0],
                taskCreationId
              })
            );
          }
        })
        .catch((error) => {
          NotificationService.error('Kunne ikke finde adresse');
          log(error);
        });
    },
    [dispatch, handleTaskLocationSelected, taskCreationId, taskLocation]
  );

  const handleExpandTaskLocation = () => {
    if (showSelectTaskLocation) {
      if (!!asset?.location) {
        let { latitude, longitude } = asset.location;

        dispatch(updateTask({ id: taskCreationId, task: { taskLocation: task.assetLocation } }));

        setTaskLocation({});
        setCoordinates({ longitude: longitude ?? 0, latitude: latitude ?? 0 });
        setMarkers([{ markerType: 'Main', lat: latitude ?? 0, lng: longitude ?? 0, key: assetId }]);

        dispatch(setAssetSearchStatus({ selectedLocation: undefined, taskCreationId, showSelectTaskLocation: false }));
      }
      dispatch(setAssetSearchStatus({ taskCreationId, showSelectTaskLocation: false }));
    } else {
      dispatch(setAssetSearchStatus({ taskCreationId, showSelectTaskLocation: true }));
    }
  };

  const handleSelectTaskLocation = (location: LocationDTO | undefined) => {
    if (location && location.longitude && location.latitude) {
      setCoordinates({ longitude: location.longitude, latitude: location.latitude });
      handleTaskLocationSelected(location);
      dispatch(setAssetSearchStatus({ selectedLocation: location, taskCreationId }));
    } else {
      setCoordinates(undefined);
      setTaskLocation({});
      setMarkers([]);
      const newTask: TaskCreationTask = { taskLocation: undefined };
      dispatch(updateTask({ id: taskCreationId, task: newTask }));
      dispatch(setAssetSearchStatus({ selectedLocation: location, taskCreationId }));
    }
  };

  return (
    <Container>
      <SectionHeader>Søgning</SectionHeader>
      <SearchSection direction="row" noBorder>
        <ColumnFlex2 rowGap="28px">
          <LocationSearch
            handleLocationSelected={handleAssetLocationSelected}
            handleLocationsReceived={handleAssetLocationsReceived}
            locationResults={assetLocations}
            selectedAsset={asset}
            errors={task.baseErrors}
          />

          <Checkbox
            data-testid="task-location-checkbox"
            label="Ikke på samme adresse som asset"
            checked={showSelectTaskLocation}
            onChange={handleExpandTaskLocation}
          />

          {showSelectTaskLocation && (
            <Row>
              <AddressAutocomplete
                onSelect={handleSelectTaskLocation}
                prevLocation={selectedLocation}
                label="Adresse - udførelse"
                noOptionsText="Ingen resultater"
              />
            </Row>
          )}
        </ColumnFlex2>
        <ColumnFlex2>
          <MapDiv>
            <MapComponent
              markers={markers}
              handleOnClick={handleMapClick}
              activateOnClick={showSelectTaskLocation}
              options={{ keyboardShortcuts: false }}
            />
          </MapDiv>
        </ColumnFlex2>
      </SearchSection>
      <Section>
        <SectionHeader>Adgangsforhold</SectionHeader>
        <AccessConditionsSection>
          <TextRow label={'Nøgleboks'} value={accessConditions?.keyBoxInfo ?? ''} />
          <TextRow label={'Adgang'} value={accessConditions?.accessInfo ?? ''} />
          <TextRow label={'Teknikergodkendelse'} value={accessConditions?.technicianApproval ?? ''} />
          <Button
            variant="secondary"
            isLoading={accessConditionIsLoading}
            onClick={() => setShowAccessConditionsDialog(true)}
            disabled={
              !asset?.assetId || (asset?.assetType !== AssetType2.Netstation && asset?.assetType !== AssetType2.CabelBox)
            }
          >
            Rediger
          </Button>
        </AccessConditionsSection>
      </Section>
      <Section>
        <NearbyTasks
          assetId={task.assetId ?? ''}
          coordinates={coordinates}
          taskCreationId={task.taskCreationId ?? ''}
          showAlerts
          differentAssets
        />
      </Section>
      {showAccessConditionsDialog && (
        <AccessConditionDialog
          id={taskCreationId ?? ''}
          onClose={() => setShowAccessConditionsDialog(false)}
          assetId={assetId}
          assetType={asset?.assetType}
          assetLocation={asset?.location}
          accessConditions={accessConditions}
          setAccessConditions={setAccessConditions}
        />
      )}
    </Container>
  );
};

const MapDiv = styled.div`
  height: 305px;
  flex: 1;
`;

const SearchSection = styled(Section)`
  column-gap: 32px;
`;

const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

const AccessConditionsSection = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

export default LocationStep;
