import styled from 'styled-components';

import { useSelector } from 'react-redux';

import { selectTimeRegistration } from '../../stateManagement/reducers/timeRegistrationReducer';

import TextField from '../../components/text-field/TextField';
import Button from '../../components/button/Button';
import DatePicker from '../../components/date-picker/DatePicker';
import TimePicker from '../../components/time-picker/TimePicker';
import AutoComplete from '../../components/auto-complete/AutoComplete';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import Select, { MenuItem } from '../../components/select/Select';
import Typography from '../../components/typography/Typography';
import { TimeRegFormLayoutProps } from './TimeRegFormShell';
import { ChevronLeft } from '../../assets/icons/ChevronLeft';
import { useNavigate } from '../../hooks/useNavigate';
import AppViewContainer from '../../components/app-view/AppView';

const TimeRegFormApp = (props: TimeRegFormLayoutProps) => {
  const {
    isLoading,
    selectedDate,
    setRegistrationDate,
    hours,
    minutes,
    handleHoursChange,
    selectedGroup,
    handleSelectGroup,
    mappedProjectNumbers,
    selectedProject,
    handleSelectProjectNumber,
    timeCategoryOptions,
    selectedCategoryId,
    handleSelectCategory,
    description,
    submitting,
    handleConfirm,
    groupOptions,
    showDescriptionError,
    handleChangeDescription
  } = props;

  const timeRegistration = useSelector(selectTimeRegistration);

  const navigate = useNavigate();

  const handleSaveTimeRegistration = () => {
    handleConfirm();
  };

  return (
    <AppViewContainer
      header={
        <>
          <div onClick={() => navigate(-1)}>
            <ChevronLeft />
          </div>
          <Typography variant="h4">Time registrering</Typography>
        </>
      }
      footer={
        <StyledButton isLoading={submitting} onClick={handleSaveTimeRegistration} data-testid="timereg-save-button">
          Gem
        </StyledButton>
      }
    >
      <div>
        {isLoading ? (
          <CircularProgress />
        ) : (
          <>
            <Row>
              <DatePicker
                label="Registreringsdato *"
                onDateChanged={(date) => setRegistrationDate(date.toISOString())}
                defaultDate={selectedDate}
              />
            </Row>
            <Row>
              <TimePicker initialHours={hours} initialMinutes={minutes} onTimeChange={handleHoursChange} />
            </Row>
            <Row>
              <Select
                disabled={timeRegistration?.project !== undefined}
                value={selectedGroup}
                onChange={(e: any) => handleSelectGroup(e.target.value)}
                fullWidth
              >
                {groupOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </Row>
            <Row>
              {timeRegistration?.workTaskId && timeRegistration?.project ? (
                <TextField
                  disabled
                  fullWidth
                  defaultValue={`${timeRegistration?.project?.id} - ${timeRegistration?.project?.name} (${timeRegistration?.project?.legalEntityId})`}
                />
              ) : (
                <AutoComplete
                  onChange={(_, value, reason) =>
                    handleSelectProjectNumber((value?.value.id ?? '') + value?.value.legalEntityId, reason)
                  }
                  getOptionLabel={(option) => option.label ?? ''}
                  options={mappedProjectNumbers}
                  value={selectedProject}
                  renderInput={(params) => <TextField {...params} label={'Projektnummer'} fullWidth />}
                  isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
                  noOptionsText={'Intet projektnummer'}
                  loading={true}
                  fullWidth
                  data-testid="external-project-numbers-dropdown"
                />
              )}
            </Row>
            <Row>
              <AutoComplete
                onChange={(event, value, reason) => handleSelectCategory(value, reason)}
                getOptionLabel={(option) =>
                  timeCategoryOptions
                    .filter((category) => option === category.id)
                    .map((category) => {
                      return `${category.id} - ${category.name} (${category.legalEntityId})`;
                    })[0] ?? ''
                }
                options={timeCategoryOptions.map((category) => {
                  return category.id;
                })}
                value={selectedCategoryId}
                renderInput={(params) => <TextField {...params} label={'Timetype/Art'} fullWidth />}
                placeholder="Vælg timetypen"
                fullWidth
                data-testid="timereg-category-dropdown"
              />
            </Row>
            <Row>
              <Column>
                <TextField
                  fullWidth
                  value={description}
                  multiline
                  maxRows={4}
                  minRows={4}
                  onChange={(e) => handleChangeDescription(e.target.value)}
                  label="Bemærkning"
                  placeholder="Skriv bemærkning"
                  dataTestId="timereg-description-textfield"
                />
                <StyledTypography variant="p" textAlign="right" color="error">
                  {showDescriptionError ? 'Bemærkning må kun indeholde bogstaver og tal' : ' '}
                </StyledTypography>
              </Column>
            </Row>
          </>
        )}
      </div>
    </AppViewContainer>
  );
};

const Row = styled.div`
  display: flex;
  align-items: left;
  justify-content: left;
  padding: ${(props) => props.theme.spacing(3)};
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
  margin-left: auto;
`;

const StyledTypography = styled(Typography)`
  padding: 0px;
`;
const Column = styled.div`
  flex: 1;
`;
export default TimeRegFormApp;
