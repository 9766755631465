import { IconProps } from './IconProps';

import IconSvg from '../../components/svg-icon/IconSvg';

export const ImageErrorIcon = (props: IconProps) => {
  return (
    <IconSvg {...props} viewBox="0 0 116 116" xmlns="http://www.w3.org/2000/svg">
      <path d="M114.499 89.8757L102.833 78.209V13.1673H37.791L26.1244 1.50065H102.833C106.041 1.50065 108.788 2.64301 111.072 4.92773C113.357 7.21246 114.499 9.95899 114.499 13.1673V89.8757ZM107.499 115.834L98.166 106.501H21.166C17.9577 106.501 15.2112 105.358 12.9264 103.074C10.6417 100.789 9.49935 98.0423 9.49935 94.834V17.834L0.166016 8.50065L8.33268 0.333984L115.666 107.667L107.499 115.834ZM26.9994 83.1673L44.4994 59.834L57.6244 77.334L62.4369 70.9173L21.166 29.6465V94.834H86.3535L74.6869 83.1673H26.9994Z" />
    </IconSvg>
  );
};
