import { createContext, Dispatch, SetStateAction, useCallback, useEffect, useReducer } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  AccessConditionsDTO,
  AssetResultDTO,
  CustomerAppointmentDTO,
  ProjectDTO2,
  TaskComponentAttributeValueDTO,
  TaskComponentDTO,
  TaskComponentValueDTO,
  UpdateAttributeValueDTO,
  UpdateWorkTaskDTO2
} from '../../../../api/api';
import AccessConditionDialog from '../../../../blocks/access-conditions-dialog/AccessConditionDialog';
import { TabProps } from '../../../../blocks/tabs-vertical/TabsVertical';
import TaskTypeTemplateComponent from '../../../../blocks/tasktype-template-component/TaskTypeTemplateComponent';
import Button from '../../../../components/button/Button';
import {
  MappedProjectNumber,
  projectNumberToOption
} from '../../../../components/project-number-dropdown/ProjectNumberDropdown';
import { useConfirmationDialog } from '../../../../hooks/useConfirmationDialog';
import useFormIsDirty from '../../../../hooks/useFormIsDirty';
import { WorkTaskDTOExtended } from '../../../../models/TaskType';
import { TaskComponent } from '../../../../models/TaskTypeComponent';
import AssetService from '../../../../services/AssetService';
import NotificationService from '../../../../services/NotificationService';
import ProjectsService from '../../../../services/ProjectsService';
import TaskService from '../../../../services/TaskService';
import { DialogBody } from '../../../../stateManagement/reducers/confirmDialogReducer';
import { setListShouldUpdate } from '../../../../stateManagement/reducers/taskListReducer';
import { Container, Section, SectionContent, SectionHeader } from '../../../../styling/FormStyling';
import { StyledCircularProgress } from '../../../../styling/Styling';
import { locationsAreEqual } from '../../../../utils/location/locationHandling';
import { log } from '../../../../utils/logging/log';
import StretchesSection, {
  StretchContainer
} from '../../../create-task-view/details-step/sections/stretches/StretchesSection';
import TextRow from '../components/TextRow';
import NearbyTasks from '../../../../blocks/nearby-tasks/NearbyTasks';
import { CustomerAppointment } from './components/CustomerAppointment';
import { AccessConditions } from './components/AccessConditions';
import { Details } from './components/Details';
import { Information } from './components/Information';
import { SubTasks } from './components/Subtasks';
import { ExternalResources } from './components/ExternalResources';
import { DetailStep, getInitialState, PayloadAction, reducer, TaskErrors } from './TaskDetailsReducer';
import { AppointmentErrors } from '../../../../models/CreationErrors';
import { MeterInformation } from './components/MeterInformation';

interface Props extends TabProps {
  task?: WorkTaskDTOExtended;
  customerAppointment?: CustomerAppointmentDTO;
  editable: boolean;
  setEditable: Dispatch<SetStateAction<boolean>>;
  setWorkTaskCallback: Dispatch<SetStateAction<WorkTaskDTOExtended | undefined>>;
  scrollIntoPreviousPosition: (id: string) => void;
  workInvoice?: number;
  workInvoiceProjectNumber?: string;
  accessConditions?: AccessConditionsDTO;
  isUpdatingTask?: boolean;
  projectNumberObject?: MappedProjectNumber;
}

export const TaskDetailsContext = createContext<DetailStep>({});
export const TaskDetailsDispatchContext = createContext<React.Dispatch<PayloadAction>>((value: any) => {});

const TaskDetailsStep = (props: Props) => {
  const {
    editable,
    setEditable,
    setWorkTaskCallback,
    tabId,
    scrollIntoPreviousPosition,
    workInvoice,
    workInvoiceProjectNumber
  } = props;

  const dispatch = useDispatch();
  const [taskDetails, dispatchTaskDetails] = useReducer(reducer, getInitialState(props.task, props.customerAppointment));
  const {
    task,
    projectNumberAndName,
    components,
    customerAppointment,
    deadline,
    department,
    description,
    dynamicFieldErrors,
    earliestStartDate,
    externalProjects,
    isProjectNumberEditable,
    isUpdatingTask,
    notesForPlanning,
    projectNumberObject,
    remainingTime,
    showAccessConditionsDialog,
    stretches,
    taskLocation,
    workTaskErrors,
    accessConditions,
    isAccessConditionsLoading
  } = taskDetails;
  const { getConfirmation } = useConfirmationDialog();
  const { isDirty, toggleFieldIsDirty, resetFields } = useFormIsDirty();

  useEffect(() => {
    dispatchTaskDetails({ type: 'initialize', payload: getInitialState(props.task, props.customerAppointment) });
  }, [props.customerAppointment, props.task]);

  useEffect(() => {
    if (task && task.assetId && task.assetType) {
      dispatchTaskDetails({ type: 'set-is-access-conditions-loading', payload: true });
      AssetService.getAssets(task.assetId, task.assetType)
        .then((res) => {
          const assetResultDto: AssetResultDTO[] = res;
          dispatchTaskDetails({ type: 'set-access-conditions', payload: assetResultDto[0].accessConditions });
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Kunne ikke hente adgangsforhold fra GIS.', 5000);
        })
        .finally(() => {
          dispatchTaskDetails({ type: 'set-is-access-conditions-loading', payload: false });
        });
    }
  }, [dispatchTaskDetails, task]);

  useEffect(() => {
    if (props.task?.components) {
      const newValues = props.task.components.map((component) => {
        return { ...component };
      });
      dispatchTaskDetails({ type: 'set-components', payload: newValues });
    }
  }, [dispatchTaskDetails, props.task?.components]);

  useEffect(() => {
    if (projectNumberAndName === '-' && task?.projectNumber) {
      TaskService.getProjectById(task.projectNumber)
        .then((res) => {
          dispatchTaskDetails({ type: 'set-project-number-and-name', payload: `${res.id} - ${res.name}` });
        })
        .catch((err) => {
          dispatchTaskDetails({ type: 'set-project-number-and-name', payload: task?.projectNumber ?? '' });
          log(err);
        })
        .finally(() => {
          tabId && scrollIntoPreviousPosition(tabId);
        });
    }
  }, [dispatchTaskDetails, scrollIntoPreviousPosition, tabId, projectNumberAndName, task?.projectNumber]);

  useEffect(() => {
    if ((externalProjects ?? []).length === 0 && task && (editable || isProjectNumberEditable)) {
      dispatchTaskDetails({ type: 'set-is-loading-project-numbers', payload: true });
      ProjectsService.getProjectsByCategory(undefined)
        .then((_externalProjects: ProjectDTO2[]) => {
          dispatchTaskDetails({ type: 'set-external-projects', payload: _externalProjects });
          if (task?.projectNumber) {
            const selectedProject = _externalProjects.find((p) => p.id === task?.projectNumber);
            selectedProject &&
              dispatchTaskDetails({ type: 'set-project-number-object', payload: projectNumberToOption(selectedProject) });
          }
        })
        .catch((err) => {
          log(err);
        })
        .finally(() => {
          dispatchTaskDetails({ type: 'set-is-loading-project-numbers', payload: false });
        });
    }
  }, [editable, isProjectNumberEditable, dispatchTaskDetails, externalProjects, task]);

  const handleChangeDynamicField = useCallback(
    (componentId: number, fieldId: number, value: any) => {
      toggleFieldIsDirty('dynamicFields', true);

      //Both components and attributes arrays and specific attribute must be copied

      if (!components) return;

      const comp = components.find((c: TaskComponentValueDTO) => c.id === componentId);
      if (!comp || !comp.attributes) return;

      let newComp = { ...comp, attributes: [...comp?.attributes] };
      const att = newComp.attributes?.find((a: TaskComponentAttributeValueDTO) => a.attributeId === fieldId);
      const attIndex = newComp.attributes?.findIndex((a: TaskComponentAttributeValueDTO) => a.attributeId === fieldId);

      if (att) {
        newComp.attributes[attIndex] = { ...att, value };
      }

      dispatchTaskDetails({
        type: 'set-components',
        payload: components.map((c: TaskComponentValueDTO) => {
          return c.id === newComp.id ? newComp : c;
        })
      });
    },
    [dispatchTaskDetails, components, toggleFieldIsDirty]
  );

  const handleValidateTask = (): TaskErrors => {
    let errors: TaskErrors = {};

    if (!description) errors.description = true;

    return errors;
  };

  const handleValidateAppointment = (): AppointmentErrors => {
    let errors: AppointmentErrors = {};
    if (!customerAppointment?.customerName) errors.customerName = true;

    if (!customerAppointment?.customerPhoneNumber || customerAppointment?.customerPhoneNumber.length !== 8)
      errors.customerPhoneNumber = true;

    return errors;
  };

  const getNewWorkTaskDto = (componentAttributes?: UpdateAttributeValueDTO[]) => {
    const { startDate, endDate } = customerAppointment ?? {};
    const newStartDate = customerAppointment?.approvedByPlanning ? startDate : earliestStartDate;
    const newEndDate = customerAppointment?.approvedByPlanning ? endDate : deadline;

    return {
      description: description !== task?.description ? description : undefined,
      assignedToDepartmentId: department !== task?.assignedToDepartment ? department?.departmentId : undefined,
      earliestStartDate: newStartDate !== task?.earliestStartDate ? newStartDate : undefined,
      deadline: newEndDate !== task?.deadline ? newEndDate : undefined,
      customerAppointment,
      remainingTimeMin: remainingTime !== task?.remainingTimeMin ? remainingTime : undefined,
      taskLocation: !locationsAreEqual(taskLocation, task?.taskLocation ?? {}) ? taskLocation : undefined,
      notesForPlanning: notesForPlanning !== task?.notesForPlanning ? notesForPlanning : undefined,
      componentAttributes,
      projectLegalEntityId:
        projectNumberObject?.value.legalEntityId !== task?.projectLegalEntityId
          ? projectNumberObject?.value.legalEntityId
          : undefined,
      projectNumber: projectNumberObject?.value.id !== task?.projectNumber ? projectNumberObject?.value.id : undefined,
      stretches
    } as UpdateWorkTaskDTO2;
  };

  const saveUpdatedTask = () => {
    if (!task?.id) return;
    if (
      earliestStartDate &&
      deadline &&
      new Date(earliestStartDate).setHours(0, 0, 0, 0) > new Date(deadline).setHours(0, 0, 0, 0)
    ) {
      NotificationService.error('Startdatoen ligger efter slutdatoen. Ændre dette og prøv igen');
      return;
    }

    const workTaskErrors = handleValidateTask();
    let customerAppointmentErrors = {};

    dispatchTaskDetails({ type: 'set-work-task-errors', payload: workTaskErrors });

    if (customerAppointment) {
      customerAppointmentErrors = handleValidateAppointment();
      dispatchTaskDetails({ type: 'set-appointment-errors', payload: customerAppointmentErrors });
    }

    let mandatoryFieldsFilled = true;

    const componentAttributes = components?.reduce(
      (acc: any, component: TaskComponentValueDTO): UpdateAttributeValueDTO[] => {
        component.attributes?.forEach((a) => {
          if (a.isMandatory && !a.value) mandatoryFieldsFilled = false;

          if (a.isEditable) acc.push({ value: a.value, id: a.id });
        });
        return acc;
      },
      [] as UpdateAttributeValueDTO[]
    );

    if (!mandatoryFieldsFilled) {
      dispatchTaskDetails({ type: 'set-dynamic-field-errors', payload: true });
    }

    if (Object.keys(customerAppointmentErrors).length || !mandatoryFieldsFilled || Object.keys(workTaskErrors).length)
      return;

    const diff = getNewWorkTaskDto(componentAttributes);
    dispatchTaskDetails({ type: 'set-is-updating-task', payload: true });

    TaskService.updateWorkTask(parseInt(task?.id), diff)
      .then((newTask) => {
        setWorkTaskCallback(newTask);
        dispatchTaskDetails({ type: 'set-customer-appointment', payload: newTask.customerAppointment });
        setEditable(false);
        resetFields();
        dispatch(setListShouldUpdate(true));
        NotificationService.success('Opgaven blev opdateret.');
      })
      .catch((err) => {
        log(err);
        NotificationService.error('Der opstod en fejl i opdateringen af arbejdskortet - ændringerne er ikke gemt');
      })
      .finally(() => {
        dispatchTaskDetails({ type: 'set-is-updating-task', payload: false });
      });
  };

  const cancelChanges = async () => {
    if (!isDirty) {
      setEditable(false);
      dispatchTaskDetails({ type: 'set-is-project-number-editable', payload: false });
      dispatchTaskDetails({ type: 'set-work-task-errors', payload: {} });
      dispatchTaskDetails({ type: 'set-appointment-errors', payload: {} });
      return;
    }

    const confirmation = await getConfirmation(dialogBody);
    if (confirmation === 'confirm') {
      dispatchTaskDetails({ type: 'set-components', payload: task?.components });
      dispatchTaskDetails({ type: 'set-description', payload: task?.description });
      dispatchTaskDetails({ type: 'set-department', payload: task?.assignedToDepartment });
      dispatchTaskDetails({ type: 'set-earliest-start-date', payload: task?.earliestStartDate });
      dispatchTaskDetails({ type: 'set-deadline', payload: task?.deadline });
      dispatchTaskDetails({ type: 'set-customer-appointment', payload: task?.customerAppointment });
      dispatchTaskDetails({ type: 'set-notes-for-planning', payload: task?.notesForPlanning });
      dispatchTaskDetails({ type: 'set-task-location', payload: task?.taskLocation });
      dispatchTaskDetails({ type: 'set-is-project-number-editable', payload: false });
      setEditable(false);
      const selectedProject = (externalProjects ?? []).find((p: ProjectDTO2) => p.id === task?.projectNumber);
      selectedProject &&
        dispatchTaskDetails({ type: 'set-project-number-object', payload: projectNumberToOption(selectedProject) });

      dispatchTaskDetails({ type: 'set-work-task-errors', payload: {} });
      dispatchTaskDetails({ type: 'set-appointment-errors', payload: {} });

      resetFields();
    }
  };

  const saveNewProjectNumber = () => {
    if (!task?.id) return;
    dispatchTaskDetails({ type: 'set-is-updating-task', payload: true });
    TaskService.updateProjectNumber(parseInt(task.id), {
      projectLegalEntityId: projectNumberObject?.value.legalEntityId,
      projectNumber: projectNumberObject?.value.id
    })
      .then(() => {
        dispatchTaskDetails({
          type: 'set-task',
          payload: {
            ...task,
            projectNumber: projectNumberObject?.value.id,
            projectLegalEntityId: projectNumberObject?.value.id
          }
        });
        dispatchTaskDetails({
          type: 'set-project-number-and-name',
          payload: `${projectNumberObject?.value.id} - ${projectNumberObject?.value.name} (${projectNumberObject?.value.legalEntityId})`
        });

        setEditable(false);
        resetFields();
        dispatch(setListShouldUpdate(true));
        dispatchTaskDetails({ type: 'set-is-project-number-editable', payload: false });
        setEditable(false);
        NotificationService.success('Projektnummeret blev opdateret.');
      })
      .catch((err) => {
        NotificationService.error('Der skete en fejl. Projektnummeret blev ikke opdateret.');
        log(err);
      })
      .finally(() => {
        dispatchTaskDetails({ type: 'set-is-updating-task', payload: false });
      });
  };

  return (
    <TaskDetailsContext.Provider value={taskDetails}>
      <TaskDetailsDispatchContext.Provider value={dispatchTaskDetails}>
        <Container>
          <Information toggleFieldIsDirty={toggleFieldIsDirty} editable={editable} task={task} />
          <SubTasks task={task} />
          <Section>
            <SectionHeader>Arbejdsbeskrivelse{editable && '*'}</SectionHeader>
            <StyledSectionContent>
              <TextRow
                inputMode={editable}
                type="multilineText"
                label="Arbejdsbeskrivelse"
                value={task?.description ?? '-'}
                onBlur={(text: string) => dispatchTaskDetails({ type: 'set-description', payload: text })}
                isDirty={toggleFieldIsDirty}
                error={workTaskErrors?.description}
                hideLabel
              />
            </StyledSectionContent>
          </Section>

          {task?.meterDetails && <MeterInformation meterInformation={task.meterDetails} />}
          <AccessConditions
            isAccessConditionsLoading={isAccessConditionsLoading}
            setShowAccessConditionsDialog={(value) =>
              dispatchTaskDetails({ type: 'set-show-access-conditions-dialog', payload: value })
            }
            accessConditions={accessConditions}
            task={task}
          />

          <Details
            task={task}
            cancelChanges={cancelChanges}
            isDirty={isDirty}
            saveNewProjectNumber={saveNewProjectNumber}
            workInvoice={workInvoice}
            workInvoiceProjectNumber={workInvoiceProjectNumber}
            editable={editable}
            toggleFieldIsDirty={toggleFieldIsDirty}
          />

          {customerAppointment && <CustomerAppointment editable={editable} toggleFieldIsDirty={toggleFieldIsDirty} />}
          {task?.stretches && task.stretches?.length > 0 && (
            <Section>
              <SectionHeader>Strækninger</SectionHeader>
              <StretchContainer>
                <StretchesSection
                  value={stretches}
                  onStretchChange={(value) => dispatchTaskDetails({ type: 'set-stretches', payload: value })}
                  isEditable={editable}
                  mode={editable ? 'write' : 'read'}
                />
              </StretchContainer>
            </Section>
          )}
          {components &&
            components.map((c: TaskComponentDTO) => (
              <TaskTypeTemplateComponent
                component={c as TaskComponent}
                onChange={handleChangeDynamicField}
                mode={editable ? 'write' : 'read'}
                key={c.id}
                showError={dynamicFieldErrors}
              />
            ))}
          {editable && (
            <StyledFooter>
              <Button onClick={() => cancelChanges()} variant="secondary" data-testid="cancel-update-task-button">
                {<div>Annuller</div>}
              </Button>
              <Button onClick={() => saveUpdatedTask()} data-testid="confirm-update-task-button" disabled={!isDirty}>
                {isUpdatingTask ? <StyledCircularProgress size={16} /> : <div>Gem</div>}
              </Button>
            </StyledFooter>
          )}
          {showAccessConditionsDialog && (
            <AccessConditionDialog
              id={task?.id ?? ''}
              onClose={() => dispatchTaskDetails({ type: 'set-show-access-conditions-dialog', payload: false })}
              assetId={task?.assetId ?? ''}
              assetType={task?.assetType}
              assetLocation={task?.taskLocation}
              accessConditions={accessConditions}
              setAccessConditions={(conditions) =>
                dispatchTaskDetails({ type: 'set-access-conditions', payload: conditions })
              }
            />
          )}
          <ExternalResources externalLinks={task?.taskType?.externalResources} />
          <Section>
            <NearbyTasks
              assetId={task?.assetId ?? ''}
              coordinates={{
                longitude: task?.taskLocation?.longitude ?? 0,
                latitude: task?.taskLocation?.latitude ?? 0
              }}
              workTaskId={task?.id}
            />
          </Section>
        </Container>
      </TaskDetailsDispatchContext.Provider>
    </TaskDetailsContext.Provider>
  );
};

const dialogBody: DialogBody = {
  headerText: 'Vil du annullere dine ændringer til arbejdskortet?',
  bodyText: '',
  declineButtonText: 'Fortryd',
  confirmButtonText: 'Bekræft'
};

export const StyledSectionContent = styled((props) => <SectionContent {...props} direction="row" />)`
  div {
    row-gap: 24px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 24px 12px 4px 12px;

  position: sticky;
  bottom: 0;

  z-index: ${(props) => props.theme.zIndex.main};
  border-top: 1px solid ${(props) => props.theme.palette.grey.black10};
  background-color: white;
  && > * {
    margin-left: 12px;
  }
`;

export default TaskDetailsStep;
