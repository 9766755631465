import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Coords } from 'google-map-react';
import { useDispatch, useSelector } from 'react-redux';
import MapComponent, { MapMarker } from '../../../components/map-component/MapComponent';
import { getDistanceInDaysFromToday } from '../../../utils/dateHandling';
import { store } from '../../../stateManagement/store';
import TaskService from '../../../services/TaskService';
import DayOverview from './day-overview/DayOverview';
import { WorkTaskStatus, WorkPlanItemDTO, BaseWorkTaskTypeEnum, WorkplanOrderDTO } from '../../../api/api';
import {
  getSelectedDate,
  getSelectedTaskId,
  getWorkPlanViewShouldUpdate,
  setSelectedTaskId,
  setWorkPlanShouldUpdate
} from '../../../stateManagement/reducers/workPlanViewReducer';
import CircularProgress from '../../../components/circular-progress/CircularProgress';
import { log } from '../../../utils/logging/log';
import TechnicianHeader from '../../../components/technician-header/WorkPlanHeader';
import { newTask, selectTasks } from '../../../stateManagement/reducers/taskCreationReducer';
import SplitButton from '../../../components/split-button/SplitButton';
import { EDIT_TASK_ROUTE } from '../../../utils/constants';
import NotificationService from '../../../services/NotificationService';
import addQueryParams from '../../../utils/routing/add-query-params';
import { getGuid } from '../../../utils/guidGenerating';
import { useNavigate } from 'react-router-dom';
import { WorkPlanItemDTOExtended } from '../../../models/WorkPlanItem';
import InternalTimeForm from '../../../blocks/internal-time-form/InternalTimeForm';
import OrdersService from '../../../services/OrdersService';

const handleOnSelectTask = (taskId: number | undefined) => {
  store.dispatch(setSelectedTaskId(taskId));
};

const WorkPlanView = () => {
  const [daySortedTasks, setDaySortedTasks] = useState<Map<number, WorkPlanItemDTOExtended[]>>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [mapMarkers, setMapMarkers] = useState<MapMarker[]>([]);
  const [showDoneMarkers, setShowDoneMarkers] = useState<boolean>(false);
  const [internalTimeDialog, setInternalTimeDialog] = useState<boolean>(false);
  const selectedTaskId = useSelector(getSelectedTaskId);
  const selectedDate = useSelector(getSelectedDate);
  const workPlanViewShouldUpdate = useSelector(getWorkPlanViewShouldUpdate);
  const [taskReadyForDriveAndStart, setTaskReadyForDriveAndStart] = useState<number | undefined>();
  const openedTasks = useSelector(selectTasks);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [workPlanOrders, setWorkPlanOrders] = useState<WorkplanOrderDTO[]>();
  const [loadingWorkPlanOrders, setLoadingWorkPlanOrders] = useState(false);

  useEffect(() => {
    fetchTasks();
  }, []);

  useEffect(() => {
    if (!selectedTaskId) return;
    setLoadingWorkPlanOrders(true);
    OrdersService.getOrdersForWorkplan(selectedTaskId)
      .then((data) => {
        setWorkPlanOrders(data);
        setMapMarkers((prev) => [
          ...prev,
          ...data.map((dropPoint) => {
            return {
              key: dropPoint.order?.orderId?.toString(),
              lat: dropPoint.dropPoint?.latitude,
              lng: dropPoint.dropPoint?.longitude,
              markerType: 'Dropsted'
            } as MapMarker;
          })
        ]);
      })
      .catch((error) => {
        log(error);
      })
      .finally(() => {
        setLoadingWorkPlanOrders(false);
      });
  }, [selectedTaskId]);

  useEffect(() => {
    if (workPlanViewShouldUpdate) {
      fetchTasks();
      dispatch(setWorkPlanShouldUpdate(false));
    }
  }, [workPlanViewShouldUpdate, dispatch]);

  useEffect(() => {
    let mapMarkersTmp: MapMarker[] = [];
    if (daySortedTasks === undefined) {
      return;
    }
    const tmpPoints: Coords[] = [];
    const daysFromToday = (selectedDate && getDistanceInDaysFromToday(selectedDate)) ?? 0;
    let tasksOfSelectedDay =
      getSelectedDate !== undefined || getDistanceInDaysFromToday(getSelectedDate) < 0
        ? daySortedTasks.get(daysFromToday)
        : undefined;

    tasksOfSelectedDay?.forEach((task) => {
      if (
        !!task.tasklocation?.latitude &&
        !!task.tasklocation.longitude &&
        (task.status !== WorkTaskStatus.Completed || (task.status === WorkTaskStatus.Completed && showDoneMarkers))
      ) {
        mapMarkersTmp.push({
          key: task.workTaskId?.toString() ?? '',
          lat: task.tasklocation.latitude,
          lng: task.tasklocation.longitude,
          markerType: task.workTaskId === selectedTaskId ? 'FocusedMain' : 'Main',
          onClick: () => handleOnSelectTask(task.workTaskId)
        });
        tmpPoints.push({ lat: task.tasklocation.latitude, lng: task.tasklocation.longitude });
      }
    });

    setMapMarkers(mapMarkersTmp);
  }, [selectedTaskId, daySortedTasks, selectedDate, showDoneMarkers]);

  const fetchTasks = () => {
    if (!isSearching) {
      setIsSearching(true);
      let daySortedTasksTmp = new Map<number, WorkPlanItemDTO[]>();
      daySortedTasksTmp.set(0, []);
      let openDay = 0;
      let WorkPlanItems: WorkPlanItemDTOExtended[];
      TaskService.getWorkPlan()
        .then((response: WorkPlanItemDTOExtended[]) => {
          if (response.length !== 0) {
            WorkPlanItems = response as WorkPlanItemDTOExtended[];
            response
              .filter((t) => {
                if (
                  t.plannedArrival &&
                  (getDistanceInDaysFromToday(new Date(t.plannedArrival)) === 0 ||
                    (getDistanceInDaysFromToday(new Date(t.plannedArrival)) < 0 &&
                      t.status !== WorkTaskStatus.Completed &&
                      t.status !== WorkTaskStatus.Pause &&
                      t.status !== WorkTaskStatus.Processed))
                ) {
                  WorkPlanItems.forEach((task) => {
                    if (task.workTaskId === t.workTaskId && task.plannedArrival !== t.plannedArrival) {
                      t.hideTask = getDistanceInDaysFromToday(new Date(t.plannedArrival ?? 0)) !== 0;
                    }
                  });
                  if (!t.hideTask) return t;
                }
                return;
              })
              .sort((a, b) => {
                if (!!a.plannedArrival && !!b.plannedArrival) {
                  return new Date(a.plannedArrival).getTime() - new Date(b.plannedArrival).getTime();
                }
                if (!!a.plannedArrival) {
                  return -1;
                }
                if (!!b.plannedArrival) {
                  return 1;
                }
                return 0;
              })
              .forEach((t) => {
                if (!!t.plannedArrival) {
                  let daysFromToday = getDistanceInDaysFromToday(new Date(t.plannedArrival));

                  if (daysFromToday < 0) {
                    daysFromToday = -1;
                    openDay = -1;
                  }

                  if (daysFromToday !== undefined && daySortedTasksTmp.has(daysFromToday)) {
                    daySortedTasksTmp.get(daysFromToday)?.push(t);
                  } else if (daysFromToday !== undefined) {
                    daySortedTasksTmp.set(daysFromToday, [t]);
                  }
                }
              });
          }

          if (openDay === -1) {
            daySortedTasksTmp = new Map([...daySortedTasksTmp.entries()].sort());
          }

          if (daySortedTasksTmp !== undefined && daySortedTasksTmp.size > 0) {
            let relevantTasks = daySortedTasksTmp.get(0) ?? [];
            let firstNotDoneTask = relevantTasks.find(
              (x) =>
                x.status !== WorkTaskStatus.Completed &&
                x.status !== WorkTaskStatus.Pause &&
                x.status !== WorkTaskStatus.Processed &&
                x.baseWorkTaskType !== BaseWorkTaskTypeEnum.Absence
            );
            if (!!firstNotDoneTask) {
              setTaskReadyForDriveAndStart(firstNotDoneTask.workTaskId ?? undefined);
              store.dispatch(setSelectedTaskId(firstNotDoneTask?.workTaskId));
            }
          }
          setDaySortedTasks(daySortedTasksTmp);
        })
        .catch((error) => {
          log(error);
        })
        .finally(() => {
          setIsSearching(false);
        });
    }
  };

  const toggleShowDoneMarkers = (showDoneTasks: boolean) => {
    setShowDoneMarkers(showDoneTasks);
  };

  const onClickCreateTask = () => {
    if (openedTasks.length < 3) {
      const taskId = getGuid();
      dispatch(newTask({ taskCreationId: taskId }));
      navigate(addQueryParams(undefined, { id: taskId }, EDIT_TASK_ROUTE));
    } else {
      NotificationService.warning('Der kan højst være 3 åbne arbejdskort. Luk venligst en af de åbne arbejdskort.');
    }
  };

  return (
    <Container>
      <TasksContainer>
        <TechnicianHeader
          handleUpdateTasks={fetchTasks}
          header="Dine opgaver"
          button={
            <SplitButton
              variant="secondary"
              options={[
                { label: 'Opret opgave', onClick: onClickCreateTask },
                { label: 'Opret intern opgave', onClick: () => setInternalTimeDialog(true) }
              ]}
            />
          }
        />
        {isSearching ? (
          <CircularProgress />
        ) : (
          Array.from(daySortedTasks ?? [], ([key, value]) => ({ key, value })).map((day) => {
            return (
              <DayOverview
                fetchTasks={fetchTasks}
                key={day.key}
                tasks={day.value}
                handleShowDoneTasks={toggleShowDoneMarkers}
                taskReadyForDriveAndStart={taskReadyForDriveAndStart}
                date={day.value[0]?.plannedArrival ? new Date(day.value[0].plannedArrival) : undefined}
                loadingWorkPlanOrders={loadingWorkPlanOrders}
                workPlanOrders={workPlanOrders}
              />
            );
          })
        )}
      </TasksContainer>
      <MapContainer>
        <MapComponent markers={mapMarkers} />
      </MapContainer>
      {internalTimeDialog && <InternalTimeForm onClose={() => setInternalTimeDialog(false)} />}
    </Container>
  );
};

const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
`;

const TasksContainer = styled.div`
  overflow-y: scroll;
  width: 100%;
  flex: 3;
`;

const MapContainer = styled.div`
  width: 100%;
  flex: 2;
  max-height: 100%;
  overflow-y: scroll;
  overflow-x: clip;
`;

export default WorkPlanView;
