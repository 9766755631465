import styled from 'styled-components';

interface Props {
  header?: React.ReactNode;
  footer?: React.ReactNode;
  testId?: string;
  children: React.ReactNode;
}

const AppViewContainer = (props: Props) => {
  const { header, footer, testId, children } = props;
  return (
    <OuterShell>
      <InnerShell data-testid={testId}>
        {header && <Header flex={1}>{header}</Header>}
        {children}
        {footer && <Footer> {footer}</Footer>}
      </InnerShell>
    </OuterShell>
  );
};

const OuterShell = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const InnerShell = styled.div`
  @media (min-width: 500px) {
    width: 400px;
    border-radius: 4px;
    border: 1px solid;
    border-color: ${(props) => props.theme.palette.grey.black90};
    height: calc(100% - 48px);
    margin: 0;
  }

  background-color: white;
  display: flex;
  flex-direction: column;
  padding: 0 ${(props) => props.theme.spacing(3)};
  width: 100%;
  height: 100%;
  max-height: 850px;
  overflow-y: auto;
  margin-top: ${(props) => props.theme.spacing(3)};
  row-gap: ${(props) => props.theme.spacing(3)};
`;

interface FlexProp {
  flex?: number;
}
export const FlexContainer = styled.div<FlexProp>`
  display: flex;
  flex-direction: column;
  flex: ${(props) => props.flex};
  row-gap: 15px;
`;

const Header = styled(FlexContainer)`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 0;
  position: sticky;
  top: 0;
  background-color: ${(props) => props.theme.palette.background.primary};
  z-index: 1;
  width: 100%;
  padding: ${(props) => props.theme.spacing(3)} 0;
`;

const Footer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: auto;
  flex: 0;
  position: sticky;
  bottom: 0;
  background-color: ${(props) => props.theme.palette.background.primary};
  z-index: 1;
  padding: ${(props) => props.theme.spacing(3)} 0;
  width: 100%;
`;

export default AppViewContainer;
