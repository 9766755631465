import { Route, Routes, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  ADD_TIME_ROUTE,
  EDIT_TASK_ROUTE,
  IMAGE_ROUTE,
  MASS_CREATION,
  PORTAL_ROUTE,
  PROFILE_ROUTE,
  SCHEDULE_ROUTE,
  TASKS_DETAILS_DIALOG_ROUTE,
  TECHNICIANDUTY_ROUTE,
  TIME_ROUTE,
  GOODS_ROUTE,
  TASKS_DETAILS,
  COUNT_GOODS_ROUTE,
  ORDER_GOODS_ROUTE,
  MOVE_GOODS_ROUTE,
  RETURN_GOODS_ROUTE,
  RETURN_RECEIPT_ROUTE,
  ORDER_RECEIPT_ROUTE,
  NEWS_ROUTE,
  TIME_APP_ROUTE,
  TIME_ADD_APP_ROUTE,
  SCANNER
} from './utils/constants';
import CreateTaskView from './views/create-task-view/CreateTaskView';
import Shell from './views/shell/Shell';

import TimeRegDialog from './blocks/timereg-dialog/TimeRegDialog';
import routeInPathname from './utils/routing/route-in-pathname';
import PortalView from './views/portal-view/PortalView';
import ProfileView from './views/profile-view/ProfileView';
import TaskDetailsView from './views/task-view/task-details-view2/TaskDetailsView';
import TechnicianDutyShell from './views/technician-duty-view/TechnicianDutyShell';

import UserRoleRoutes from './UserRole-routes';
import MassCreationTaskView from './views/mass-creation-task-view/MassCreationTaskView';
import ScheduleView from './views/schedule-view/ScheduleView';
import Image from './views/image-view/image';
import { useNavigate } from './hooks/useNavigate';
import GoodsView from './views/goods-view/GoodsView';
import CountGoodsView from './views/goods-view/count-goods-dialog/CountGoodsView';
import OrderGoodsView from './views/goods-view/order-goods-dialog/OrderGoodsView';
import MoveGoodsView from './views/goods-view/move-goods-dialog/MoveGoodsView';
import ReturnGoodsView from './views/goods-view/return-goods-dialog/ReturnGoodsView';
import ReturnReceiptView from './views/goods-view/goods-view-tabs/return-goods-tab/ReturnReceiptView';
import OrderReceiptView from './views/goods-view/goods-view-tabs/orders-tab/order-receipt/OrderReceiptView';
import NewsView from './views/news-view/NewsView';
import TimeRegFormShell from './blocks/timereg-form/TimeRegFormShell';
import TimeRegViewShell from './views/time-reg-view/TimeRegViewShell';
import BarcodeScannerComponent from './views/barcode-scanner/Barcode-scanner';

const App = () => {
  const path = useLocation().pathname;
  const includedRoute = routeInPathname(path);
  const navigate = useNavigate();

  return (
    <Container>
      <Routes>
        <Route path={SCANNER} element={<BarcodeScannerComponent />} />
        <Route path={IMAGE_ROUTE} element={<Image />} />
        <Route path={TECHNICIANDUTY_ROUTE} element={<TechnicianDutyShell />} />
        <Route path={TIME_APP_ROUTE} element={<TimeRegViewShell />} />
        <Route path={TIME_ADD_APP_ROUTE} element={<TimeRegFormShell />} />

        <Route path="/*" element={<Shell />}>
          <Route path={TIME_ROUTE + '/*'} element={<TimeRegViewShell />} />
          <Route path={SCHEDULE_ROUTE + '/*'} element={<ScheduleView />} />
          <Route path={PORTAL_ROUTE} element={<PortalView />} />

          <Route path={TASKS_DETAILS} element={<TaskDetailsView />} />

          <Route path={GOODS_ROUTE} element={<GoodsView />} />
          <Route path={COUNT_GOODS_ROUTE} element={<CountGoodsView />} />
          <Route path={ORDER_GOODS_ROUTE} element={<OrderGoodsView />} />
          <Route path={MOVE_GOODS_ROUTE} element={<MoveGoodsView />} />
          <Route
            path={RETURN_GOODS_ROUTE}
            element={<ReturnGoodsView showReceipt={() => navigate(RETURN_RECEIPT_ROUTE)} />}
          />
          <Route path={RETURN_RECEIPT_ROUTE} element={<ReturnReceiptView />} />
          <Route path={ORDER_RECEIPT_ROUTE} element={<OrderReceiptView />} />
          <Route path={NEWS_ROUTE} element={<NewsView />} />

          {UserRoleRoutes()}
        </Route>
      </Routes>

      {includedRoute === ADD_TIME_ROUTE && <TimeRegDialog />}
      {includedRoute === EDIT_TASK_ROUTE && <CreateTaskView />}
      {includedRoute === TASKS_DETAILS_DIALOG_ROUTE && <TaskDetailsView />}
      {includedRoute === MASS_CREATION && <MassCreationTaskView />}
      {includedRoute === PROFILE_ROUTE && <ProfileView />}
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  height: 100%;
`;

export default App;
