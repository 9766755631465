import styled from 'styled-components';
import SelectedCurrent from '../../assets/gifs/SelectedCurrent.gif';
import Current from '../../assets/icons/Current.svg';
import DropstedIcon from '../../assets/icons/DropPointIcon.svg';
import ToolTip from '../tool-tip/ToolTip';
import Created from '../../assets/icons/MapMarkerCreated.svg';
import Ready from '../../assets/icons/MapMarkerReadyForPlanning.svg';
import Planned from '../../assets/icons/MapMarkerPlanned.svg';
import PlannedAppointment from '../../assets/icons/MapMarkerPlannedAppointment.svg';
import OnRoute from '../../assets/icons/MapMarkerOnRoute.svg';
import Ongoing from '../../assets/icons/MapMarkerOngoing.svg';
import Pause from '../../assets/icons/MapMarkerPause.svg';
import Completed from '../../assets/icons/MapMarkerCompleted.svg';
import Processed from '../../assets/icons/MapMarkerProcessed.svg';
import { WorkTaskStatus } from '../../api/api';

export type MarkerType = 'Main' | 'FocusedMain' | WorkTaskStatus | 'Dropsted';
interface MapMarkerProps {
  lat: number;
  lng: number;
  markerType: MarkerType;
  onClick?: () => void;
  hoverText?: string;
}

const MapMarkerIcon = (props: MapMarkerProps) => {
  const { onClick, markerType, hoverText } = props;

  let imageSrc = '';

  switch (markerType) {
    case 'Main':
      imageSrc = Current;
      break;
    case 'FocusedMain':
      imageSrc = SelectedCurrent;
      break;
    case 'Dropsted':
      imageSrc = DropstedIcon;
      break;
    case WorkTaskStatus.Created:
      imageSrc = Created;
      break;
    case WorkTaskStatus.ReadyForPlanning:
      imageSrc = Ready;
      break;
    case WorkTaskStatus.Planned:
      imageSrc = Planned;
      break;
    case WorkTaskStatus.PlannedAppointment:
      imageSrc = PlannedAppointment;
      break;
    case WorkTaskStatus.OnRoute:
      imageSrc = OnRoute;
      break;
    case WorkTaskStatus.Ongoing:
      imageSrc = Ongoing;
      break;
    case WorkTaskStatus.Pause:
      imageSrc = Pause;
      break;
    case WorkTaskStatus.Completed:
      imageSrc = Completed;
      break;
    case WorkTaskStatus.Processed:
      imageSrc = Processed;
      break;
  }

  if (!!hoverText) {
    return (
      <Container pointer={!!onClick} onClick={() => !!onClick && onClick()}>
        <ToolTip title={hoverText ?? ''}>
          <img src={imageSrc} alt="" />
        </ToolTip>
      </Container>
    );
  }

  return (
    <Container pointer={!!onClick} onClick={() => !!onClick && onClick()}>
      <img src={imageSrc} alt="" />
    </Container>
  );
};

const Container = styled.div<{ pointer: boolean }>`
  position: absolute;
  transform: translateZ(0) translate(-50%, -100%);

  &:hover {
    cursor: ${(props) => (props.pointer ? 'pointer' : '')};
  }
`;
export default MapMarkerIcon;
