import { loginRequest, msalInstance } from '../utils/authProvider/authProvider';

export default class ClientBase {
  protected async transformOptions(options: any) {
    try {
      var token = (await msalInstance.acquireTokenSilent(loginRequest)).accessToken;
      options.headers['Authorization'] = 'Bearer ' + token;
      return options;
    } catch (error) {
      return msalInstance.acquireTokenRedirect(loginRequest);
    }
  }
}
