import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';

import { CategoryDTO2, CreateTimeRegistrationDTO, ProjectDTO2, UpdateTimeRegistrationDTO } from '../../api/api';
import { clearTimeRegistration, selectTimeRegistration } from '../../stateManagement/reducers/timeRegistrationReducer';
import { formatDateStringISO } from '../../utils/dateHandling';
import TimeRegistrationService from '../../services/TimeRegistrationService';
import NotificationService from '../../services/NotificationService';
import { log } from '../../utils/logging/log';
import { RadioButtonOptions } from '../../components/radio-buttons/RadioButtons';
import { TIME_ADD_APP_ROUTE } from '../../utils/constants';
import TimeRegFormApp from './TimeRegFormApp';
import TimeRegFormDefault from './TimeRegFormDefault';
import { isCharacterLetterOrNumber } from '../../utils/stringHandling';
import { useNavigate } from '../../hooks/useNavigate';

const groupOptions = [
  { label: 'Afdelingsregistrering', value: 'Afdeling' },
  { label: 'Fravær', value: 'Fravær' },
  { label: 'Øvrige', value: 'Øvrige' },
  { label: 'PPM', value: 'PPM' }
] as RadioButtonOptions[];

export interface MappedProjectNumber {
  label: string;
  value: ProjectDTO2;
}
export interface TimeRegFormLayoutProps {
  isLoading: boolean;
  selectedDate: Date;
  setRegistrationDate: (date: string | null) => void;
  hours: number;
  minutes: number;
  handleHoursChange: (hours: number, minutes: number) => void;
  selectedGroup: string;
  handleSelectGroup: (value: string) => void;
  mappedProjectNumbers: MappedProjectNumber[];
  selectedProject: MappedProjectNumber | null;
  handleSelectProjectNumber: (value: string, reason: string) => void;
  timeCategoryOptions: CategoryDTO2[];
  selectedCategoryId: string;
  handleSelectCategory: (value: string | null, reason: string) => void;
  description: string;
  handleChangeDescription: (value: string) => void;
  submitting: boolean;
  handleConfirm: () => void;
  groupOptions: RadioButtonOptions[];
  showDescriptionError: boolean;
}

interface Props {
  handleReturn?: () => void;
}

const TimeRegFormShell = (props: Props) => {
  const { handleReturn } = props;

  const dispatch = useDispatch();
  const timeRegistration = useSelector(selectTimeRegistration);
  const timeRegAction = timeRegistration?.timeRegAction ?? 'add';

  const [registrationDate, setRegistrationDate] = useState<string | null>(
    timeRegistration?.date ?? formatDateStringISO(new Date().toISOString())
  );
  const [hours, setHours] = useState<number>(Math.trunc(timeRegistration?.hours ?? 0));
  const [minutes, setMinutes] = useState<number>(((timeRegistration?.hours ?? 0.25) % 1) * 60);
  const [description, setDescription] = useState(timeRegistration?.description ?? '');
  const [showDescriptionError, setShowDescriptionError] = useState(false);
  const [timeCategoryOptions, setTimeCategoryOptions] = useState<CategoryDTO2[]>([]);
  const [selectedGroup, setSelectedGroup] = useState(timeRegistration?.categoryGroupId ?? groupOptions[0].value);
  const [selectedCategoryId, setSelectedCategoryId] = useState(timeRegistration?.category?.id ?? '');
  const [selectedDate] = useState(timeRegistration?.date ? new Date(timeRegistration?.date) : new Date());
  const [projectNumberOptions, setProjectNumberOptions] = useState<ProjectDTO2[]>([]);
  const [selectedProject, setSelectedProject] = useState<MappedProjectNumber | null>(null);
  const [submitting, setSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(timeRegAction === 'edit');

  const location = useLocation();
  const navigate = useNavigate();

  const onClose = () => {
    if (location.pathname.includes(TIME_ADD_APP_ROUTE)) {
      navigate(-1);
    } else {
      handleReturn && handleReturn();
    }
  };

  useEffect(() => {
    if ((selectedProject?.value.id || timeRegistration?.project?.id) && timeRegAction !== 'edit') {
      TimeRegistrationService.getCategories(selectedProject?.value.id ?? timeRegistration?.project?.id)
        .then((res) => setTimeCategoryOptions(res))
        .catch((err) => log(err));
    } else {
      TimeRegistrationService.getCategoryAndProjectFromGroup(selectedGroup)
        .then((res) => {
          if (res.categories !== undefined) setTimeCategoryOptions(res.categories);
          if (res.projects !== undefined) setProjectNumberOptions(res.projects);
          if (timeRegistration?.workTaskId && timeRegistration?.project) setIsLoading(false);
        })
        .catch((err) => log(err));
    }
  }, [
    selectedCategoryId,
    selectedGroup,
    selectedProject?.value.id,
    timeRegAction,
    timeRegistration?.project,
    timeRegistration?.project?.id,
    timeRegistration?.workTaskId
  ]);

  useEffect(() => {
    if (timeCategoryOptions.length > 0 && !selectedCategoryId) {
      timeRegistration?.category?.id && setSelectedCategoryId(timeRegistration?.category.id);
    }
  }, [selectedCategoryId, timeCategoryOptions, timeRegistration?.category]);

  useEffect(() => {
    if (
      timeRegAction === 'edit' &&
      isLoading &&
      (timeRegistration?.workTaskId || timeRegistration?.project) &&
      projectNumberOptions.length > 0
    ) {
      const project =
        projectNumberOptions
          .map((p) => projectNumberToOption(p))
          .find((i) => i.value.id === timeRegistration?.project?.id) ?? null;
      setSelectedProject(project);
      project && setIsLoading(false);

      timeRegistration?.category && setSelectedCategoryId(timeRegistration?.category.id);
    }
  }, [
    projectNumberOptions,
    isLoading,
    timeRegAction,
    timeRegistration?.category,
    timeRegistration?.project,
    timeRegistration?.workTaskId
  ]);

  const handleSave = () => {
    let project = selectedProject?.value;
    if (!project && timeRegistration?.project) {
      project = timeRegistration?.project;
    }
    let category = timeCategoryOptions.find((c) => c.id === selectedCategoryId);
    if (project && selectedCategoryId && registrationDate) {
      setSubmitting(true);
      const { legalEntityId: projectLegalEntityId, id: projectId } = project;
      const body: CreateTimeRegistrationDTO = {
        date: formatDateStringISO(registrationDate) ?? '',
        categoryId: selectedCategoryId,
        hours: getTotalHours(),
        projectLegalEntityId: projectLegalEntityId,
        legalEntityId: category?.legalEntityId,
        description,
        projectId,
        categoryGroupId: selectedGroup,
        workTaskId: timeRegistration?.workTaskId
      };
      TimeRegistrationService.addTimeRegistration(body)
        .then(() => {
          NotificationService.success('Tiden blev registreret');
          setSubmitting(false);
          onClose();
          dispatch(clearTimeRegistration());
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Der opstod en fejl i registreringen af tid');
          setSubmitting(false);
        });
    } else {
      NotificationService.error('Udfyld venligst alle nødvendige felter');
    }
  };

  const handleEdit = () => {
    let project = selectedProject?.value;

    if (!project && timeRegistration?.project) {
      project = timeRegistration?.project;
    }
    let category = timeCategoryOptions.find((c) => c.id === selectedCategoryId);
    if (project && selectedCategoryId && registrationDate && timeRegistration?.id) {
      setSubmitting(true);
      const { legalEntityId: projectLegalEntityId, id: projectId } = project;
      const body: UpdateTimeRegistrationDTO = {
        date: formatDateStringISO(registrationDate) ?? '',
        categoryId: selectedCategoryId,
        hours: getTotalHours(),
        projectLegalEntityId: projectLegalEntityId,
        legalEntityId: category?.legalEntityId,
        description,
        projectId
      };
      TimeRegistrationService.editTimeRegistration(timeRegistration?.id, body)
        .then((res) => {
          NotificationService.success('Registreringen blev opdateret');
          setSubmitting(false);
          onClose();
          dispatch(clearTimeRegistration());
        })
        .catch((err) => {
          log(err);
          NotificationService.error('Der opstod en fejl under opdatering af tidsregistreringen');
          setSubmitting(false);
        });
    } else {
      NotificationService.error('Udfyld venligst alle nødvendige felter');
    }
  };

  const handleConfirm = () => {
    if (timeRegAction === 'add') {
      handleSave();
    } else if (timeRegAction === 'edit') {
      handleEdit();
    }
  };

  const handleHoursChange = useCallback((hours: number, minutes: number) => {
    const totalHours = hours + minutes / 60.0;
    if (totalHours > -0.25 && totalHours < 0.25) {
      NotificationService.warning('Tiden skal mindst være et kvarter');
    } else {
      setHours(hours);
      setMinutes(minutes);
    }
  }, []);

  const getTotalHours = useCallback(() => {
    return hours + minutes / 60.0;
  }, [hours, minutes]);

  const handleSelectCategory = useCallback(
    (value: string | undefined | null, reason) => {
      if (reason === 'clear') {
        setSelectedCategoryId('');
      }

      const category = timeCategoryOptions.find((x) => x.id === value);
      setSelectedCategoryId(category?.id ?? '');
    },
    [timeCategoryOptions]
  );

  const projectNumberToOption = (p: ProjectDTO2): MappedProjectNumber => {
    return {
      label: `${p.id} - ${p.name} (${p.legalEntityId})`,
      value: p
    };
  };

  const mappedProjectNumbers = useMemo(() => {
    return projectNumberOptions.map((p) => projectNumberToOption(p));
  }, [projectNumberOptions]);

  const handleSelectProjectNumber = useCallback(
    (value: string | undefined, reason) => {
      if (reason === 'clear') {
        setSelectedProject(null);
      }
      const project = mappedProjectNumbers.find((project) => project.value.id + project.value.legalEntityId === value);
      project && setSelectedProject(project);
    },
    [mappedProjectNumbers]
  );

  const handleSelectGroup = useCallback(
    (value: string) => {
      if (value === selectedGroup) return;
      setSelectedGroup(value);
      setTimeCategoryOptions([]);
      setProjectNumberOptions([]);
      handleSelectProjectNumber('', 'clear');
      handleSelectCategory('', 'clear');
      TimeRegistrationService.getCategoryAndProjectFromGroup(value)
        .then((res) => {
          if (res.categories !== undefined) setTimeCategoryOptions(res.categories);

          if (res.projects !== undefined) setProjectNumberOptions(res.projects);
        })
        .catch((err) => log(err));
    },
    [handleSelectCategory, handleSelectProjectNumber, selectedGroup]
  );

  const handleChangeDescription = (value: string) => {
    if (isCharacterLetterOrNumber(value)) {
      setShowDescriptionError(true);
      return;
    }
    setDescription(value);
    setShowDescriptionError(false);
  };

  if (location.pathname.includes(TIME_ADD_APP_ROUTE)) {
    return (
      <TimeRegFormApp
        isLoading={isLoading}
        selectedDate={selectedDate}
        setRegistrationDate={setRegistrationDate}
        hours={hours}
        minutes={minutes}
        handleHoursChange={handleHoursChange}
        selectedGroup={selectedGroup}
        handleSelectGroup={handleSelectGroup}
        mappedProjectNumbers={mappedProjectNumbers}
        selectedProject={selectedProject}
        handleSelectProjectNumber={handleSelectProjectNumber}
        timeCategoryOptions={timeCategoryOptions}
        selectedCategoryId={selectedCategoryId}
        handleSelectCategory={handleSelectCategory}
        description={description}
        submitting={submitting}
        handleConfirm={handleConfirm}
        groupOptions={groupOptions}
        showDescriptionError={showDescriptionError}
        handleChangeDescription={handleChangeDescription}
      />
    );
  }

  return (
    <TimeRegFormDefault
      isLoading={isLoading}
      selectedDate={selectedDate}
      setRegistrationDate={setRegistrationDate}
      hours={hours}
      minutes={minutes}
      handleHoursChange={handleHoursChange}
      selectedGroup={selectedGroup}
      handleSelectGroup={handleSelectGroup}
      mappedProjectNumbers={mappedProjectNumbers}
      selectedProject={selectedProject}
      handleSelectProjectNumber={handleSelectProjectNumber}
      timeCategoryOptions={timeCategoryOptions}
      selectedCategoryId={selectedCategoryId}
      handleSelectCategory={handleSelectCategory}
      description={description}
      submitting={submitting}
      handleConfirm={handleConfirm}
      groupOptions={groupOptions}
      showDescriptionError={showDescriptionError}
      handleChangeDescription={handleChangeDescription}
    />
  );
};

export default TimeRegFormShell;
