import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { ItemConsumptionDTO, LocationDTO, OrderDTO, OrderStatus, ProjectDTO2 } from '../../../../api/api';
import { AddIcon } from '../../../../assets/icons/AddIcon';
import {
  ContentFooterContainer,
  Footer,
  FooterLeft,
  FooterRight,
  TabProps
} from '../../../../blocks/tabs-vertical/TabsVertical';
import Button from '../../../../components/button/Button';
import LoadingOverlay from '../../../../components/loading-overlay/LoadingOverlay';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '../../../../components/table/Table';
import Typography from '../../../../components/typography/Typography';
import GoodsService from '../../../../services/GoodsService';
import TaskService from '../../../../services/TaskService';
import { log } from '../../../../utils/logging/log';
import GoodsTableRow from '../components/GoodsTableRow';
import NoDataTableRow from '../components/NoDataTableRow';
import ConsumeGoodsPage from './ConsumeGoodsPage';
import { ShopIcon } from '../../../../assets/icons/ShopIcon';
import { useDispatch, useSelector } from 'react-redux';
import {
  setWorkTaskOrderInfo,
  selectWorkTaskOrderInfo,
  setOrderGoodsList,
  setSelectedDeliveryLocation,
  setDeliveryDate,
  setNotes,
  setSelectedOrderReceipt,
  selectSelectedOrderReceipt
} from '../../../../stateManagement/reducers/orderGoodsReducer';
import OrderGoodsContent, { OrderGoodsPage } from '../../../goods-view/order-goods-dialog/OrderGoodsContent';
import DialogViewPageHeader from '../../../../components/dialog-view-page-header/DialogViewPageHeader';
import useWorkTaskOrdersTable from './useWorkTaskOrdersTable';
import TableClient from '../../../../blocks/table/TableClient';
import OrderReceiptContent from '../../../goods-view/goods-view-tabs/orders-tab/order-receipt/OrderReceiptContent';
import StatusTag from '../../../../components/status-tag/StatusTag';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import { userAllowedThroughFeatureFlag } from '../../../../utils/validation/userAllowedFeatureFlag';

enum GoodsPage {
  STANDARD = 'standard',
  ADD = 'add',
  RECEIPT = 'receipt'
}

type Page = GoodsPage | OrderGoodsPage;

interface Props extends TabProps {
  taskId: string;
  projectNumber: string;
  projectLegalEntityId: string;
  taskLocation?: LocationDTO;
}

const TaskGoodsStep = (props: Props) => {
  const { taskId, projectNumber, projectLegalEntityId, taskLocation } = props;

  const [viewState, setViewState] = useState<'loading' | 'ready' | 'error'>('loading');
  const [page, setPage] = useState<Page>(GoodsPage.STANDARD);
  const [itemOrders, setItemOrders] = useState<ItemConsumptionDTO[]>([]);
  const [workOrders, setWorkOrders] = useState<OrderDTO[]>([]);
  const [project, setProject] = useState<ProjectDTO2>();

  const selectedWorkTaskOrderInfo = useSelector(selectWorkTaskOrderInfo);
  const order = useSelector(selectSelectedOrderReceipt);

  const dispatch = useDispatch();

  useEffect(() => {
    if (taskId) {
      const consumedItemsPromise = GoodsService.getConsumedItems(parseInt(taskId)).then((res) => setItemOrders(res));
      const projectPromise = TaskService.getProjectById(projectNumber).then((res) => setProject(res));
      const workOrderPromise = GoodsService.getActiveOrdersByWorkTask(parseInt(taskId)).then((res) => setWorkOrders(res));

      Promise.all([consumedItemsPromise, projectPromise, workOrderPromise])
        .then(() => setViewState('ready'))
        .catch((error) => {
          log(error);
          setViewState('error');
        });
    }
  }, [taskId, projectNumber]);

  const handleOpenOrderGoods = () => {
    if (selectedWorkTaskOrderInfo?.workTaskId !== taskId) {
      dispatch(setOrderGoodsList([]));
      dispatch(setSelectedDeliveryLocation({ id: '', name: '' }));
      dispatch(setDeliveryDate(''));
      dispatch(setNotes(''));
      dispatch(setSelectedOrderReceipt(undefined));
    }
    dispatch(
      setWorkTaskOrderInfo({
        workTaskId: taskId,
        projectNumber: projectNumber,
        projectLegalEntityId: projectLegalEntityId,
        address: taskLocation
      })
    );
    setPage(OrderGoodsPage.SELECT);
  };

  const openOrderReceipt = useCallback(
    (order?: OrderDTO) => {
      if (!order) return;
      dispatch(setSelectedOrderReceipt(order));
      setPage(GoodsPage.RECEIPT);
    },
    [dispatch]
  );

  const tableInstance = useWorkTaskOrdersTable(workOrders, openOrderReceipt);

  if (page === OrderGoodsPage.SELECT || page === OrderGoodsPage.ORDER) {
    const shouldReturn =
      page === OrderGoodsPage.SELECT ? () => setPage(GoodsPage.STANDARD) : () => setPage(OrderGoodsPage.SELECT);
    const title = page === OrderGoodsPage.SELECT ? 'Bestil varer' : 'Varekurv';
    return (
      <>
        {viewState === 'loading' && <LoadingOverlay />}
        {viewState === 'ready' && (
          <>
            <DialogViewPageHeader handleReturn={shouldReturn} title={title} />
            <OrderGoodsContent
              onClose={() => setPage(GoodsPage.STANDARD)}
              page={page}
              setPage={(page: OrderGoodsPage) => setPage(page)}
              orderToUserVeicle={false}
              taskLocation={taskLocation}
            />
          </>
        )}
      </>
    );
  }

  if (page === GoodsPage.RECEIPT && order) {
    return (
      <>
        {viewState === 'loading' && <LoadingOverlay />}
        {viewState === 'ready' && (
          <>
            <DialogViewPageHeader
              handleReturn={() => setPage(GoodsPage.STANDARD)}
              title={'Bestilling: ' + order.transferId}
              subHeaderComponent={
                <StatusTag orderStatus={order?.orderStatus as OrderStatus}>
                  {getEnumDisplayValue(order?.orderStatus as OrderStatus)}
                </StatusTag>
              }
            />
            <OrderReceiptContent order={order} />
          </>
        )}
      </>
    );
  }

  if (page === GoodsPage.ADD) {
    return (
      <ConsumeGoodsPage
        handleReturn={() => setPage(GoodsPage.STANDARD)}
        taskId={taskId}
        projectNumber={project?.id}
        projectLegalEntity={project?.legalEntityId}
      />
    );
  }

  return (
    <>
      <Container>
        {viewState === 'loading' && <LoadingOverlay />}
        {viewState === 'ready' && (
          <>
            {userAllowedThroughFeatureFlag(window._env_.ORDER_GOODS_TO_TASK_ENABLED) && (
              <>
                <Header>
                  <DialogViewPageHeader title="Bestillinger til opgaven" />
                  <Button onClick={handleOpenOrderGoods} variant="secondary">
                    <ShopIcon size="16px" />
                    Bestil varer til opgaven
                  </Button>
                </Header>

                <TableClient
                  tableInstance={tableInstance}
                  loading={false}
                  noDataText="Der ligger ingen bestillinger på opgaven"
                  alwaysShowSort
                  noPadding
                  renderFullHeight
                />
              </>
            )}
            <DialogViewPageHeader title="Varer forbrugt på opgaven" />

            <TableContainer>
              <Table>
                <TableHead>
                  <StyledTableRow>
                    <TableCell sx={{ width: '20%' }}>Dato</TableCell>
                    <TableCell sx={{ width: '30%' }}>Lager</TableCell>
                    <TableCell sx={{ width: '30%' }}>Medarbejder</TableCell>
                    <TableCell sx={{ width: '10%' }}>Antal</TableCell>
                    <TableCell sx={{ width: '10%' }}></TableCell>
                  </StyledTableRow>
                </TableHead>
                <TableBody>
                  {itemOrders.length > 0 ? (
                    itemOrders.map((order, i) => {
                      return <GoodsTableRow key={i} itemOrder={order} />;
                    })
                  ) : (
                    <NoDataTableRow text="Ingen varer forbrugt på opgaven" />
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </>
        )}
        {viewState === 'error' && <Typography variant="h5"> Der kunne ikke hentes varer.</Typography>}
      </Container>
      <ContentFooterContainer>
        <Footer>
          <FooterLeft />
          <FooterRight>
            <Button onClick={() => setPage(GoodsPage.ADD)} variant="secondary">
              <AddIcon size="14px" />
              Forbrug fra bil
            </Button>
          </FooterRight>
        </Footer>
      </ContentFooterContainer>
    </>
  );
};

const Container = styled.div`
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: ${(props) => props.theme.spacing(5)};
`;

const StyledTableRow = styled((props) => <TableRow {...props} />)`
  && .MuiTableCell-root {
    font-weight: 800;
  }
`;

export default TaskGoodsStep;
