import { HttpTransportType, HubConnectionBuilder } from '@microsoft/signalr';
import { Middleware } from 'redux';
import { msalInstance, loginRequest } from '../../utils/authProvider/authProvider';
import { ChecklistEditedLockDTO, ChecklistLockDTO, TaskLockDTO } from '../../models/TaskLockDTO';
import {
  setEditedChecklist,
  setLockedChecklists,
  setLockedWorkTasks,
  setSignalRConnection
} from '../reducers/signalRReducer';
import { log } from '../../utils/logging/log';
import NewsService from '../../services/NewsService';
import { setNews, setShowNotification } from '../reducers/newsReducer';
import { NewsDTO } from '../../api/api';

const SIGNALR_CONNECT_START = 'SIGNALR_CONNECT_START';

const signalRMiddleware: Middleware = ({ dispatch, getState }) => {
  let connection: signalR.HubConnection | null = null;

  const removeOneReleasedChecklistLock = (lockedChecklists: ChecklistLockDTO[], releasedChecklist: ChecklistLockDTO) => {
    let filteredOut = false;
    const allLockedChecklists =
      lockedChecklists && lockedChecklists?.length > 0
        ? lockedChecklists.filter((c: ChecklistLockDTO) => {
            if (
              !filteredOut &&
              c.workTaskChecklistId === releasedChecklist.workTaskChecklistId &&
              c.email === releasedChecklist.email
            ) {
              filteredOut = true;
              return false;
            }
            return true;
          })
        : lockedChecklists;

    return allLockedChecklists;
  };

  const removeOneReleasedWorkTaskLock = (lockedWorkTasks: TaskLockDTO[], releasedWorkTask: TaskLockDTO) => {
    let filteredOut = false;
    const allLockedWorkTasks =
      lockedWorkTasks && lockedWorkTasks?.length > 0
        ? lockedWorkTasks.filter((t: TaskLockDTO) => {
            if (!filteredOut && t.workTaskId === releasedWorkTask.workTaskId && t.email === releasedWorkTask.email) {
              filteredOut = true;
              return false;
            }
            return true;
          })
        : lockedWorkTasks;

    return allLockedWorkTasks;
  };

  return (next) => (action) => {
    if (action.type === SIGNALR_CONNECT_START) {
      if (!connection) {
        connection = new HubConnectionBuilder()
          .withUrl(window._env_.REACT_APP_BBF_URL + '/workTaskHub', {
            skipNegotiation: true,
            transport: HttpTransportType.WebSockets,
            accessTokenFactory: async () => (await msalInstance.acquireTokenSilent(loginRequest)).accessToken
          })
          .withAutomaticReconnect()
          .build();

        connection
          .start()
          .then(() => {
            connection?.send('GetAllWorkTaskLocks');
            connection?.send('GetAllChecklistLocks');
            connection && dispatch(setSignalRConnection(connection));
          })
          .catch((err) => log(err));

        // WorkTasks
        connection.on('ReceiveAddWorkTaskLock', (workTask: TaskLockDTO) => {
          const lockedWorkTasks = getState().signalRReducer.lockedWorkTasks;
          const allLockedWorkTasks =
            lockedWorkTasks !== undefined ? ([...lockedWorkTasks, workTask] as TaskLockDTO[]) : [workTask];
          dispatch(setLockedWorkTasks(allLockedWorkTasks));
        });

        connection.on('ReceiveReleaseWorkTaskLock', (workTask: TaskLockDTO) => {
          const lockedWorkTasks = getState().signalRReducer.lockedWorkTasks;
          const allLockedWorkTasks = removeOneReleasedWorkTaskLock(lockedWorkTasks, workTask);

          dispatch(setLockedWorkTasks(allLockedWorkTasks));
        });

        connection.on('ReceiveWorkTaskLocks', (workTasks: TaskLockDTO[]) => {
          dispatch(setLockedWorkTasks(workTasks));
        });

        // Checklists
        connection.on('ReceiveAddChecklistLock', (checklist: ChecklistLockDTO) => {
          const lockedChecklists = getState().signalRReducer.lockedChecklists;

          const allLockedChecklists =
            lockedChecklists !== undefined ? ([...lockedChecklists, checklist] as TaskLockDTO[]) : [checklist];
          dispatch(setLockedChecklists(allLockedChecklists));
        });

        connection.on('ReceiveReleaseChecklistLock', (checklist: ChecklistLockDTO) => {
          const lockedChecklist = getState().signalRReducer.lockedChecklists;
          const allLockedChecklists = removeOneReleasedChecklistLock(lockedChecklist, checklist);

          dispatch(setLockedChecklists(allLockedChecklists));
        });

        connection.on('ReceiveChecklistLocks', (checklists: ChecklistLockDTO[]) => {
          dispatch(setLockedChecklists(checklists));
        });

        connection.on('ReceiveAddChecklistEditedLock', (checklist: ChecklistEditedLockDTO) => {
          dispatch(setEditedChecklist(checklist));
        });

        connection.on('ReceiveNewsNotification', (news: NewsDTO) => {
          NewsService.getUserNews()
            .then((response) => {
              dispatch(setNews(response));
              dispatch(
                setShowNotification({
                  show: true,
                  news
                })
              );
            })
            .catch((e) => {
              log(e);
            });
        });
      }

      return () => {
        connection?.stop();
      };
    } else {
      return next(action);
    }
  };
};

export const startSignalRConnect = () => ({ type: SIGNALR_CONNECT_START });

export default signalRMiddleware;
