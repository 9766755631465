import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { selectTimeRegistration } from '../../stateManagement/reducers/timeRegistrationReducer';
import TextField from '../../components/text-field/TextField';
import Button from '../../components/button/Button';
import DatePicker from '../../components/date-picker/DatePicker';
import TimePicker from '../../components/time-picker/TimePicker';
import AutoComplete from '../../components/auto-complete/AutoComplete';
import CircularProgress from '../../components/circular-progress/CircularProgress';
import RadioButtons from '../../components/radio-buttons/RadioButtons';
import { TimeRegFormLayoutProps } from './TimeRegFormShell';
import Typography from '../../components/typography/Typography';

const TimeRegFormDefault = (props: TimeRegFormLayoutProps) => {
  const {
    isLoading,
    selectedDate,
    setRegistrationDate,
    hours,
    minutes,
    handleHoursChange,
    selectedGroup,
    handleSelectGroup,
    mappedProjectNumbers,
    selectedProject,
    handleSelectProjectNumber,
    timeCategoryOptions,
    selectedCategoryId,
    handleSelectCategory,
    description,
    submitting,
    handleConfirm,
    groupOptions,
    showDescriptionError,
    handleChangeDescription
  } = props;

  const timeRegistration = useSelector(selectTimeRegistration);

  return (
    <Container>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <>
          <Row>
            <Column>
              <DatePicker
                label="Registreringsdato *"
                onDateChanged={(date) => setRegistrationDate(date.toISOString())}
                defaultDate={selectedDate}
              />
            </Column>
            <Column>
              <TimePicker initialHours={hours} initialMinutes={minutes} onTimeChange={handleHoursChange} />
            </Column>
          </Row>
          {!timeRegistration?.workTaskId && (
            <Row>
              <RadioButtons
                disabled={timeRegistration?.project !== undefined}
                options={groupOptions}
                label={''}
                value={selectedGroup}
                handleValueChange={(value: string) => handleSelectGroup(value)}
                labelPlacement="end"
                row
              />
            </Row>
          )}
          <Row>
            {timeRegistration?.workTaskId && timeRegistration?.project ? (
              <TextField
                disabled
                fullWidth
                defaultValue={`${timeRegistration?.project?.id} - ${timeRegistration?.project?.name} (${timeRegistration?.project?.legalEntityId})`}
              />
            ) : (
              <AutoComplete
                onChange={(_, value, reason) =>
                  handleSelectProjectNumber((value?.value.id ?? '') + value?.value.legalEntityId, reason)
                }
                getOptionLabel={(option) => option.label ?? ''}
                options={mappedProjectNumbers}
                value={selectedProject}
                renderInput={(params) => <TextField {...params} label={'Projektnummer'} fullWidth />}
                isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
                noOptionsText={'Intet projektnummer'}
                loading={true}
                fullWidth
                data-testid="external-project-numbers-dropdown"
              />
            )}

            <AutoComplete
              onChange={(event, value, reason) => handleSelectCategory(value, reason)}
              getOptionLabel={(option) =>
                timeCategoryOptions
                  .filter((category) => option === category.id)
                  .map((category) => {
                    return `${category.id} - ${category.name} (${category.legalEntityId})`;
                  })[0] ?? ''
              }
              options={timeCategoryOptions.map((category) => {
                return category.id;
              })}
              value={selectedCategoryId}
              renderInput={(params) => <TextField {...params} label={'Timetype/Art'} fullWidth />}
              placeholder="Vælg timetypen"
              fullWidth
              data-testid="timereg-category-dropdown"
            />

            {!timeRegistration?.workTaskId && !timeRegistration?.project && (
              <Button
                variant="secondary"
                onClick={(e) => {
                  handleSelectProjectNumber('', 'clear');
                  handleSelectCategory('', 'clear');
                }}
              >
                Ryd
              </Button>
            )}
          </Row>
          <Row>
            <Column>
              <TextField
                fullWidth
                value={description}
                multiline
                maxRows={4}
                minRows={4}
                onChange={(e) => handleChangeDescription(e.target.value)}
                label="Bemærkning"
                placeholder="Skriv bemærkning"
                dataTestId="timereg-description-textfield"
              />
              <StyledTypography variant="p" textAlign="right" color="error">
                {showDescriptionError ? 'Bemærkning må kun indeholde bogstaver og tal' : ' '}
              </StyledTypography>
            </Column>
          </Row>
          <StyledButton isLoading={submitting} onClick={() => handleConfirm()} data-testid="timereg-save-button">
            Gem
          </StyledButton>
        </>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 420px;
  height: auto;
  row-gap: 32px;
`;

const Row = styled.div`
  display: flex;
  column-gap: 24px;
`;

const Column = styled.div`
  flex: 1;
`;

const StyledButton = styled(Button)`
  align-self: flex-end;
`;

const StyledTypography = styled(Typography)`
  padding: 0;
`;

export default TimeRegFormDefault;
