import styled from 'styled-components';
import Typography from '@mui/material/Typography';
import Avatar from '../avatar/Avatar';
import { getCreatedByText, getUserInitials } from '../../utils/stringHandling';
import { useEffect, useState } from 'react';
import UserService from '../../services/UserService';
import { ProfileDTO2 } from '../../api/api';
import CircularProgress from '../circular-progress/CircularProgress';
import DepartmentService from '../../services/DepartmentService';
import GoodsService from '../../services/GoodsService';
import NotificationService from '../../services/NotificationService';
import { log } from '../../utils/logging/log';

export interface Contact {
  email?: string;
}

interface User extends ProfileDTO2 {
  departmentName: string;
  warehouseName: string;
}

const ContactCard = (props: Contact) => {
  const { email } = props;

  const [user, setUser] = useState<User>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (!email) return;
    setIsLoading(true);
    UserService.getUser(email)
      .then((_user) => {
        Promise.all([DepartmentService.GetDepartments(), GoodsService.getWarehouses()])
          .then(([departments, warehouses]) => {
            setUser({
              ..._user,
              departmentName: departments.find((department) => department.departmentId === _user.departmentId)?.name ?? '',
              warehouseName: warehouses.find((warehouse) => warehouse.warehouseId === _user.defaultWarehouseId)?.name ?? ''
            });
          })
          .catch((e) => {
            NotificationService.error('Kunne ikke hente afdelinger eller lagre');
            log(e);
          })
          .finally(() => setIsLoading(false));
      })
      .catch((e) => {
        NotificationService.error('Kunne ikke hente profil oplysninger');
        log(e);
      });
  }, [email]);

  return (
    <ContactContainer>
      {isLoading ? (
        <CircularProgress />
      ) : (
        <ContentContainer>
          <TopContainer>
            <Avatar fontSize={14} size={30} text={getUserInitials(user?.name)} />
            <FullName variant="h6" fontWeight="bold">
              {getCreatedByText(user?.name, email)}
            </FullName>
          </TopContainer>
          <ContactInformation fontWeight="bold" variant="subtitle1">
            Kontaktoplysninger
          </ContactInformation>
          <InfoContainer>
            <Info>
              <Label>Fulde navn</Label>
              <Value fontWeight="bold">{user?.name ?? '-'}</Value>
            </Info>
            <Info>
              <Label>Afdeling</Label>
              <Value fontWeight="bold">{user?.departmentName ?? '-'}</Value>
            </Info>
            <Info>
              <Label>Telefonnummer</Label>
              <Value fontWeight="bold">{user?.phone ?? '-'}</Value>
            </Info>
          </InfoContainer>
        </ContentContainer>
      )}
    </ContactContainer>
  );
};

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${(props) => props.theme.spacing(7.5)};
  border-radius: 150px;
  height: 220px;
  width: 450px;
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FullName = styled(Typography)`
  padding-left: ${(props) => props.theme.spacing(2.5)};
`;

const ContactInformation = styled(Typography)`
  padding-top: ${(props) => props.theme.spacing(5)};
  flex-wrap: wrap;
`;

const Info = styled.div`
  margin-top: ${(props) => props.theme.spacing(5)};
  width: 50%;
`;

const InfoContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const Label = styled(Typography)`
  color: ${(props) => props.theme.palette.grey.black60};
`;

const Value = styled(Typography)`
  color: black;
  margin-top: ${(props) => props.theme.spacing(1)};
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;
export default ContactCard;
