import WorkClient from '../api/workClient';

const createReturnOrder = (transferOrderId?: string) => {
  return WorkClient.createReturnOrderPUT(transferOrderId);
};

const getOrdersForWorkplan = (workTaskId: number) => {
  return WorkClient.getOrdersForWorkplan(workTaskId);
};

const OrdersService = {
  getOrdersForWorkplan,
  createReturnOrder
};

export default OrdersService;
