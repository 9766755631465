import { useMemo } from 'react';
import { CellProps, Column } from 'react-table';
import styled from 'styled-components';

import { OrderDTO, OrderStatus } from '../../../../api/api';
import Button from '../../../../components/button/Button';
import StatusTag from '../../../../components/status-tag/StatusTag';
import useTableInstance from '../../../../hooks/useTableInstance';
import { formatDateString, formatTimeAndDateString, sortDateTime } from '../../../../utils/dateHandling';
import { getEnumDisplayValue } from '../../../../utils/enumUtils';
import { getInitialsFromEmail } from '../../../../utils/initialsUtils';

interface OrderData {
  status?: OrderStatus;
  transferId?: number;
  toWarehouseId?: number;
  create?: string;
  additionalReceiptDate?: string;
  description?: string;
  orderId?: number;
  workTaskId?: number;
}

const useOrderTable = (
  orders: OrderDTO[],
  moveToCar: (id: number) => void,
  openOrderReceipt: (order?: OrderDTO) => void
) => {
  const data = useMemo(() => {
    if (!orders.length) return [];

    return orders.map((order) => {
      return {
        status: order.orderStatus,
        transferId: order.transferId,
        toWarehouseId: order.toWarehouseId,
        created:
          formatTimeAndDateString(order.created ?? '') + ' (' + getInitialsFromEmail(order.createdByEmail ?? '') + ')',
        additionalReceiptDate: formatDateString(order.additionalReceiptDate ?? ''),
        description: order.description,
        orderId: order.orderId,
        workTaskId: order.workTaskId
      } as OrderData;
    });
  }, [orders]);

  const columns = useMemo(() => {
    return [
      {
        Header: 'Staus',
        accessor: 'status',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        Cell: (cellProps: CellProps<OrderData>) => {
          const { cell } = cellProps;
          return <StatusTag orderStatus={cell.value}>{getEnumDisplayValue(cell.value as OrderStatus)}</StatusTag>;
        }
      },
      {
        Header: 'TransferId',
        accessor: 'transferId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Bestilt til',
        accessor: 'toWarehouseId',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: 'Oprettet',
        accessor: 'created',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        sortType: (a, b) => sortDateTime(a.values.lastUpdated, b.values.lastUpdated)
      },
      {
        Header: 'Forventet leveringsdato',
        accessor: 'additionalReceiptDate',
        width: 120,
        minWidth: 120,
        disableFilters: true,
        sortType: (a, b) => sortDateTime(a.values.lastUpdated, b.values.lastUpdated)
      },
      {
        Header: 'Bemærkning',
        accessor: 'description',
        width: 120,
        minWidth: 120,
        disableFilters: true
      },
      {
        Header: '',
        accessor: 'actions',
        width: 200,
        minWidth: 200,
        disableFilters: true,
        disableSortBy: true,
        Cell: ({ row }: CellProps<OrderData>) => (
          <ButtonsContainer>
            {row.original.status === OrderStatus.Delivered && !row.original.workTaskId && (
              <Button variant="tertiary" onClick={() => moveToCar(row.original.orderId ?? -1)}>
                Flyt til bil
              </Button>
            )}
            <Button
              variant="secondary"
              onClick={() => openOrderReceipt(orders.find((order) => order.orderId === row.original.orderId))}
            >
              Se bestilling
            </Button>
          </ButtonsContainer>
        )
      }
    ] as Column<OrderData>[];
  }, [orders]);

  return useTableInstance(data, columns, {
    initialState: {
      pageSize: 25,
      pageIndex: 0,
      sortBy: [
        {
          id: 'created',
          desc: true
        }
      ]
    }
  });
};

export default useOrderTable;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: right;
  justify-content: right;
  column-gap: ${(props) => props.theme.spacing(2)};
  margin-right: ${(props) => props.theme.spacing(5)};
`;
