import styled from 'styled-components';
import { useState, useEffect } from 'react';
import { Alert } from '@mui/material';

import { formatDate } from '../../utils/dateHandling';
import AlertMessage from '../../models/AlertMessage';
import TechnicianDutyService from '../../services/TechnicianDutyService';
import TechnicianStatusLine from './technician-status-line/TechnicianStatusLine';
import { msalInstance } from '../../utils/authProvider/authProvider';
import SelectTechnician from '../../components/select-technician/SelectTechnician';
import logo from '../../assets/icons/N1_Work_Logo.png';

import { Status, TechnicianDTO } from '../../api/api';
import { TechnicianStatus } from '../../models/TechnicianStatus';
import Button from '../../components/button/Button';
import { log } from '../../utils/logging/log';
import AppViewContainer, { FlexContainer } from '../../components/app-view/AppView';

const ALERT_ETRACK = 'HUSK at sende e-track til planlægning om denne statusændring';
const ALERT_MISSING_TECHNICIAN = 'Der skal vælges en tekniker';
const ALERT_MISSING_STATUS = 'Der skal vælges en status';
const ALERT_ERROR_SENDING = 'Noget gik galt - status er ikke opdateret';

const etrackAlert: AlertMessage = { message: ALERT_ETRACK, severity: 'info', show: true };

const handleLogout = () => {
  msalInstance.logoutRedirect();
};

const convertStatusStringToEnum = (string: string | undefined): Status | undefined => {
  switch (string) {
    case 'Vagt':
      return Status.OnCall;
    case 'Normal arbejde':
      return Status.ShiftEnded;
    case 'Fraværende':
      return Status.Absent;
    case 'Hvilevagt':
      return Status.Rest;
    default:
      return undefined;
  }
};

const TechnicianDutyShell = () => {
  const [technicians, setTechnicians] = useState<TechnicianDTO[]>([]);
  const [selectedTechnician, setSelectedTechnician] = useState<TechnicianDTO | undefined>();
  const [selectedStatusValue, setSelectedStatusValue] = useState<Status | undefined>();
  const [isSending, setIsSending] = useState<boolean>(false);
  const [alerts, setAlerts] = useState<AlertMessage[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const removeMessage = (message: string) => {
    setAlerts((prevAlerts) => {
      return prevAlerts.filter((x) => x.message !== message);
    });
  };

  const removeMessageAndSuccessAndFailureMessages = (message: string) => {
    const newAlerts = alerts.filter(
      (x) => x.severity !== 'success' && x.message !== ALERT_ERROR_SENDING && x.message !== message
    );
    setAlerts(newAlerts);
  };

  useEffect(() => {
    if (isSending) {
      return;
    }
    setIsLoading(true);
    TechnicianDutyService.getTechnicians()
      .then((response) => {
        setTechnicians(
          response.sort((first: TechnicianDTO, second: TechnicianDTO) => {
            const firstName = first?.name?.toLowerCase();
            const secondName = second?.name?.toLowerCase();
            if (!!firstName && !!secondName) {
              if (firstName < secondName) {
                return -1;
              }
              if (firstName > secondName) {
                return 1;
              }
            }
            return 0;
          })
        );
        setIsLoading(false);
      })
      .catch((error) => {
        setIsLoading(false);
        setAlerts([
          {
            message: 'Teknikere kunne ikke hentes. Prøv igen senere.',
            severity: 'error',
            show: true
          }
        ]);
      });
  }, [isSending]);

  useEffect(() => {
    if (
      (selectedStatusValue === Status.Rest || selectedStatusValue === Status.Absent) &&
      selectedStatusValue !== convertStatusStringToEnum(selectedTechnician?.currentArcGisStatus)
    ) {
      setAlerts([etrackAlert]);
    } else {
      setAlerts((alerts) => alerts.filter((x) => x.message !== ALERT_ETRACK));
    }
  }, [selectedStatusValue, selectedTechnician?.currentArcGisStatus]);

  const handleSelectTechnician = (technician: TechnicianDTO | undefined) => {
    setSelectedTechnician(technician);
    setSelectedStatusValue(convertStatusStringToEnum(technician?.currentArcGisStatus));
    if (!!technician) {
      removeMessageAndSuccessAndFailureMessages(ALERT_MISSING_TECHNICIAN);
    }
  };

  const handleSelectStatus = (status: TechnicianStatus) => {
    setSelectedStatusValue(status.status);
    if (!!status) {
      removeMessageAndSuccessAndFailureMessages(ALERT_MISSING_STATUS);
    }
  };

  let statusLines: TechnicianStatus[] = [
    {
      status: Status.OnCall,
      text: 'Vagt',
      color: '#005CE6'
    },
    {
      status: Status.ShiftEnded,
      text: 'Vagt slut',
      color: 'green'
    },
    {
      status: Status.Rest,
      text: 'Hvile',
      color: '#f4d300'
    },
    {
      status: Status.Absent,
      text: 'Fraværende',
      color: 'grey'
    }
  ];

  const resetInputs = () => {
    setSelectedStatusValue(undefined);
    setSelectedTechnician(undefined);
  };

  const handleSend = () => {
    const errors: AlertMessage[] = [];
    if (selectedTechnician === undefined) {
      errors.push({ message: ALERT_MISSING_TECHNICIAN, severity: 'error', show: true });
    }

    if (selectedStatusValue === undefined) {
      errors.push({ message: ALERT_MISSING_STATUS, severity: 'error', show: true });
    }

    if (selectedTechnician === undefined || selectedStatusValue === undefined) {
      setAlerts(errors);
      return;
    }

    const statusString = selectedStatusValue;
    const { initials, objectId } = selectedTechnician;
    if (!!objectId) {
      setIsSending(true);
      TechnicianDutyService.updateStatus({
        statusCode: statusString,
        initials: initials ?? '',
        objectId: objectId
      })
        .then((result) => {
          const date = new Date();
          setAlerts([
            {
              message: `Status er opdateret - ${formatDate(date)} kl. ${date.getHours()}.${
                (date.getMinutes() < 10 ? '0' : '') + date.getMinutes()
              }`,
              severity: 'success',
              show: true
            }
          ]);
          setIsSending(false);
          resetInputs();
          removeMessage(ALERT_ETRACK);
        })
        .catch((err) => {
          setAlerts([{ message: ALERT_ERROR_SENDING, severity: 'error', show: true }]);
          setIsSending(false);
          log(err);
        });
    }
  };

  return (
    <AppViewContainer
      header={
        <>
          <img src={logo} alt="N1 Work Logo" height="40" />
          <Button variant="secondary" onClick={handleLogout}>
            Log ud
          </Button>
        </>
      }
      footer={
        <ApproveButton onClick={handleSend} isLoading={isSending}>
          Godkend status
        </ApproveButton>
      }
      testId="technicianDutyShell"
    >
      <FlexContainer flex={1}>
        <SelectTechnician
          onSelectTechnician={handleSelectTechnician}
          technicians={technicians}
          selectedTechnician={selectedTechnician}
          isLoading={isLoading}
        />
      </FlexContainer>
      <FlexContainer flex={5}>
        {statusLines.map((status) => {
          return (
            <TechnicianStatusLine
              selectedStatusEnumValue={selectedStatusValue}
              technicianStatus={status}
              selectStatus={() => handleSelectStatus(status)}
              key={status.status}
            />
          );
        })}
      </FlexContainer>
      <FlexContainer flex={1}>
        {alerts.map((a, i) => (
          <Alert severity={a.severity} sx={{ marginBottom: '12px' }} key={i}>
            {a.message}
          </Alert>
        ))}
      </FlexContainer>
    </AppViewContainer>
  );
};

const ApproveButton = styled(Button)`
  width: 260px;
  justify-content: center;
`;

export default TechnicianDutyShell;
