import { type AuthenticationResult, EventType, PublicClientApplication } from '@azure/msal-browser';
import { AuthenticatedTemplate, MsalProvider } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';

type Tokens = {
  accessToken: string;
  idToken: string;
  expiresOn: Date | null;
};

interface Props {
  children: any;
}

export const msalConfig = {
  auth: {
    clientId: window._env_.AUTH_CLIENT_ID,
    authority: 'https://login.microsoftonline.com/a6230a1c-393a-4c9e-9938-a643402658d9',
    redirectUri: window._env_.REACT_APP_FRONTEND_URL,
    postLogoutRedirectUri: window._env_.AUTH_LOGOUT_URL
  },
  system: {
    allowNativeBroker: false
  },
  cache: {
    cacheLocation: 'localStorage', // This configures where your cache will be stored
    storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
  }
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: [window._env_.AUTH_CLIENT_ID + '/.default']
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const getUserInitials = (): string => {
  const account = msalInstance.getActiveAccount();

  if (!!account) {
    const names = account.name?.split(' ').filter((name) => name.charAt(0) !== '(');

    if (!!names?.length) {
      const firstLetter = names[0].charAt(0);
      const secondLetter = names[names.length - 1].charAt(0);

      return firstLetter + secondLetter;
    }
  }

  return 'NN';
};

export const getUserEmail = (): string => {
  const account = msalInstance.getActiveAccount();

  return account?.username ?? '';
};

export const getUserName = (): string => {
  return msalInstance.getActiveAccount()?.name ?? '';
};

export const WorkAuthProvider = (props: Props) => {
  const { children } = props;

  const [tokens, setTokens] = useState<Tokens>();

  const getValidToken = useCallback(async () => {
    if (!msalInstance) return;

    const { expiresOn } = tokens || {};

    const currentTimeInMilliseconds = Date.now();
    const expirationBufferTimeInMilliseconds = 5000;
    const isTokenExpired =
      expiresOn && currentTimeInMilliseconds + expirationBufferTimeInMilliseconds >= expiresOn.getTime();

    if (tokens && !isTokenExpired) return tokens;

    try {
      const result = await msalInstance.acquireTokenSilent(loginRequest);

      const newTokens: Tokens = {
        accessToken: result.accessToken,
        idToken: result.idToken,
        expiresOn: result.expiresOn
      };

      setTokens(newTokens);
      return newTokens;
    } catch (error) {
      console.error(error);
      msalInstance.loginRedirect(loginRequest);
    }
  }, [tokens]);

  const init = useCallback(async () => {
    if (!msalInstance) return;

    msalInstance.addEventCallback((event: any) => {
      if (!msalInstance) return;

      if (event.eventType === EventType.LOGIN_SUCCESS && (event.payload as AuthenticationResult).account) {
        const authenticationResult = event.payload as AuthenticationResult;

        const { account, accessToken, idToken, expiresOn } = authenticationResult;

        msalInstance.setActiveAccount(account);

        setTokens({
          accessToken,
          idToken,
          expiresOn
        });
      }
    });

    await msalInstance.initialize();

    const accounts = msalInstance.getAllAccounts();

    if (accounts.length > 0) {
      const account = accounts[0];
      msalInstance.setActiveAccount(account);

      await getValidToken();
    } else {
      const authenticationResult = await msalInstance.handleRedirectPromise();

      if (!authenticationResult) {
        return msalInstance.loginRedirect(loginRequest);
      }

      const { account, accessToken, idToken, expiresOn } = authenticationResult;

      msalInstance.setActiveAccount(account);

      setTokens({
        accessToken,
        idToken,
        expiresOn
      });
    }
  }, [getValidToken]);

  useEffect(() => {
    init();
  }, [init]);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticatedTemplate>{children}</AuthenticatedTemplate>
    </MsalProvider>
  );
};
